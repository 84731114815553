import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const saveFundraising = async (fundraisingData) => {
    try {
        const response = await axios.post(apiUrl + '/api/fundraising', fundraisingData);
        return response.data;
    } catch (error) {
        console.error('Error saving fundraising data:', error);
        throw error;
    }
};

export const saveCustomizedSale = async (fundraising) => {
    try {
        const response = await axios.post(apiUrl + '/secured/api/fundraising/saveCustomizedSale', fundraising);
        return response.data;
    } catch (error) {
        console.error('Error saving fundraising customized sale:', error);
        throw error;
    }
};

export const getFundraisingByProjectName = async (projectName) => {
    try {
        const response = await axios.get(`${apiUrl}/fundraising/${projectName}`);
        return response.data;
    } catch (error) {
        console.error('Error retrieving fundraising data:', error);
        throw error;
    }
};

export const getFundraisingEntryByAddress = async (address) => {
    try {
        const response = await axios.get(`/api/fundraising-entry/${address}`);
        return response.data;
    } catch (error) {
        if (error.response) {
            console.error('Error Response:', error.response.status, error.response.data);
        } else {
            console.error('Error:', error.message);
        }
    }
};
