import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const saveVesting = async (vestingData) => {
    try {
        const response = await axios.post(`${apiUrl}/api/vesting`, vestingData);
        return response.data;
    } catch (error) {
        console.error('Error saving vesting data:', error);
        throw error;
    }
};

export const getVestingByProjectName = async (projectName) => {
    try {
        const response = await axios.get(`${apiUrl}/api/vesting/${encodeURIComponent(projectName)}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching vestings by project name:", error);
        throw error;
    }
};

export const saveVestingBeneficiary = async (vestingBeneficiaryData) => {
    try {
        const response = await axios.post(`${apiUrl}/api/vestingBeneficiary`, vestingBeneficiaryData);
        return response.data;
    } catch (error) {
        console.error('Error saving vesting data:', error);
        throw error;
    }
};

export const updateVestingBeneficiary = async (vestingBeneficiaryData, beneficiaryAddress) => {
    try {
        const response = await axios.put(`${apiUrl}/api/vestingBeneficiary/${encodeURIComponent(beneficiaryAddress)}`, vestingBeneficiaryData);
        return response.data;
    } catch (error) {
        console.error('Error saving vesting data:', error);
        throw error;
    }
};

export const getVestingsByBeneficiaryAddress = async (beneficiaryAddress) => {
    try {
        const response = await axios.get(`${apiUrl}/api/vestingBeneficiary/beneficiary/${encodeURIComponent(beneficiaryAddress)}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching vestings by project name:", error);
        throw error;
    }
};

export const getVestingBeneficiaryByProjectName = async (projectName) => {
    try {
        const response = await axios.get(`${apiUrl}/api/vestingBeneficiary/project/${encodeURIComponent(projectName)}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching vestings by project name:", error);
        throw error;
    }
};

