import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay, Pagination, Navigation} from 'swiper/modules';
// Import Swiper styles
import "../../assets/scss/_slider.scss"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


const data = [
    {
        title: "Q4 2024",
        text: [
            "Linea integration",
            "Scroll integration",
            "Website revamp",
            "SM design",
            "FORC Token sale",
        ]
    },
    {
        title: "Q1 2025",
        text: [
            "Smart contract verification",
            "Internal/External testing",
            "Audit smart contracts",
        ]
    },
    {
        title: "Q2 2025",
        text: [
            "Introduction of Escrow system",
            "Curator review system for projects",
            "Partnerships with startup programs",
        ]
    },
    {
        title: "Q3 2025",
        text: [
            "Reputation system for curators",
            "Introduce additional projectlabels/filters",
            "FORC liquid restaking program",
        ]
    },
    {
        title: "Q4 2025",
        text: [
            "Develop chain-based partnership programs",
            "GMT strategy execution",
        ]
    },
    {
        title: "Q1 2026",
        text: [
            "Talent acquisition for projects",
            "Al powered early due diligence",
        ]
    },
    {
        title: "Q2 2026",
        text: [
            "Non-EVM chain integrations",
        ]
    },
    {
        title: "Q3 2026",
        text: [
            "Forcefi DEFI platform",
        ]
    },
];


export default function Roadmap(props) {
    function QuarterCard({ title, text }) {
        return (
            <div className="quarter-card">
                <div className="-title">{title}</div>
                <ul>
                    {text.map((item, index) => (
                        <li key={index} className="-text">{item}</li>
                    ))}
                </ul>
            </div>
        );
    }
    return (
        <div>
            <Swiper
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                }}
                // centeredSlides={true}
                spaceBetween={30}
                grabCursor={true}
                pagination={{
                    clickable: true,
                }}
                observeParents={true}
                effect={"fade"}
                observer={true}

                modules={[Autoplay, Pagination, Navigation]}
                className="slider roadmap"
            >
                {data.map((quarter, index) => (
                    <SwiperSlide className='-item' key={index}>
                       <QuarterCard key={index} title={quarter.title} text={quarter.text} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}
