import { useEffect, useState } from 'react';
import * as ethers from "ethers";

export function useAccount() {
    const [account, setAccount] = useState(null);

    useEffect(() => {
        async function getAccount() {
            if (window.ethereum) {
                try {
                    const provider = new ethers.BrowserProvider(window.ethereum);
                    const accounts = await provider.send("eth_requestAccounts", []);
                    setAccount(accounts[0]);
                } catch (error) {
                    console.error("Failed to get accounts:", error);
                }
            }
        }
        getAccount();
    }, []);

    return account;
}
