import React, { useState, useEffect } from 'react';
import "../assets/scss/_home.scss";
import "../assets/scss/_components.scss";
import Discord from "../assets/icons/footer/discord_white.svg";
import Medium from "../assets/icons/footer/medium_white.svg";
import Linkedin from "../assets/icons/footer/linkedin_white.svg";
import Twitter from "../assets/icons/footer/twitter_white.svg";
import distribution from "../assets/images/home/distribution.svg";


export default function ForcSale() {
    const socials = [
        { href: "https://discord.com/invite/CgK8PkyDMW", src: Discord, alt: "Discord" },
        { href: "https://forcefi.medium.com", src: Medium, alt: "Medium" },
        { href: "https://www.linkedin.com/company/forcefi/", src: Linkedin, alt: "LinkedIn" },
        { href: "https://twitter.com/Forcefi_io", src: Twitter, alt: "Twitter" },
    ];

    const saleEndDate = new Date("2025-05-15T00:00:00").getTime();

    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date().getTime();
            const difference = saleEndDate - now;

            if (difference > 0) {
                return {
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                    minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                    seconds: Math.floor((difference % (1000 * 60)) / 1000)
                };
            } else {
                return { days: 0, hours: 0, minutes: 0, seconds: 0 };
            }
        };

        setTimeLeft(calculateTimeLeft());

        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [saleEndDate]);

    const formatNumber = (num) => {
        return num < 10 ? `0${num}` : num;
    };

    return (
        <div className="forcefi-n">
            <div className='-hero forc-sale'>

                <div className="-wrapper">
                    <div className='-text-block '>
                        <div className="-title xl">FORC token</div>
                        <div className="-text xl shadow">Invest in FORC and get access to the most exciting AI token sales. Earn token rewards, enjoy exclusive perks, and monetize your skills while shaping the future of Web3.
                        </div>
                    </div>
                    {/* <div className='-distribution'>
                        <img src={distribution} className='-img distribution'/>
                    </div> */}
                    <div className='token-sale'>
                        <div>
                            <div className='-sale-title'>
                                The <span>Forcefi</span> token sale
                            </div>
                            <div className='-sale-title s'>
                                will begin soon
                            </div>
                        </div>

                        <div className='-timer'>
                            <div className='-item'>
                                <div className='-item-text offset'>{formatNumber(timeLeft.days)}</div>
                                <div className='-item-text s'>{timeLeft.days === 1 ? 'Day' : 'Days'}</div>
                            </div>
                            <div className='-item'>
                                <div className='-item-text offset'>{formatNumber(timeLeft.hours)}</div>
                                <div className='-item-text s'>{timeLeft.hours === 1 ? 'Hour' : 'Hours'}</div>
                            </div>
                            <div className='-item'>
                                <div className='-item-text offset'>{formatNumber(timeLeft.minutes)}</div>
                                <div className='-item-text s'>{timeLeft.minutes === 1 ? 'Min' : 'Mins'}</div>
                            </div>
                            <div className='-item'>
                                <div className='-item-text offset'>{formatNumber(timeLeft.seconds)}</div>
                                <div className='-item-text s'>{timeLeft.seconds === 1 ? 'Second' : 'Seconds'}</div>
                            </div>
                        </div>

                        {/* <button>View more</button>   */}

                        <div className='-socials'>
                            {socials.map((social, index) => (
                                <a
                                    key={index}
                                    className='-item'
                                    href={social.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img className='-img' src={social.src} alt={social.alt} />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
