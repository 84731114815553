import React from 'react';
import PolygonMumbai from "../../assets/icons/header/polygonMumbai.svg";
import Binance from "../../assets/icons/header/binance.svg";
import Ethereum from "../../assets/icons/header/ethereum.svg";
import Optimism from "../../assets/icons/header/optimism.svg";
import Arbitrum from "../../assets/icons/arbitrum.svg";
import AllChains from "../../assets/icons/AllChains.svg";
import Zksync from "../../assets/icons/header/zksync.svg";

const chains = {
    AllChains: { icon: AllChains, label: "All chains" },
    arbitrum: { icon: Arbitrum, label: "Arbitrum" },
    "arbitrum-goerli": { icon: Arbitrum, label: "Arbitrum" },
    homestead: { icon: Ethereum, label: "Ethereum" },
    goerli: { icon: Ethereum, label: "Ethereum" },
    bsc: { icon: Binance, label: "Binance" },
    matic: { icon: PolygonMumbai, label: "Polygon" },
    "optimism-goerli": { icon: Optimism, label: "Optimism" },
    optimism: { icon: Optimism, label: "Optimism" },
    zksync: { icon: Zksync, label: "Zksync" },
};

const ChainFilter = ({ queryChain, handleChangeChain, isProd }) => {
    const filteredChains = Object.keys(chains).filter(chain => {
        if (isProd) {
            return chain !== "optimism-goerli" && chain !== "goerli" && chain !== "arbitrum-goerli";
        }
        return chain !== "optimism" && chain !== "homestead" && chain !== "arbitrum";
    });

    return (
        <div className='chain-select'>
            {filteredChains.map((chainKey) => {
                const { icon, label } = chains[chainKey];
                return (
                    <div
                        key={chainKey}
                        className={queryChain === chainKey ? "-item active" : "-item"}
                        onClick={() => handleChangeChain(chainKey)}
                    >
                        <img src={icon} alt={label} className="-img" />
                        {label}
                    </div>
                );
            })}
        </div>
    );
};

export default ChainFilter;
