import React, { useState, useEffect } from 'react';
import { Card, Input, Button, Typography, Space } from 'antd';
import { TrophyOutlined } from '@ant-design/icons';
import {
    claim,
    getCurrentStakingReward,
    getCurrentStakingRewardPerToken, getTotalLocked, getTotalStaked,
    getTotalStakedByAddress, stake, unstake
} from "../../ethers/LinearStaking";
import {approveAmount} from "../../ethers/ERC20Contract";
import erc20InvestmentTokens from "../../constants/ERC20InvestmentTokens";
import Web3 from "web3";

const { Text } = Typography;

const ForcToken = () => {
    const [stakeAmount, setStakeAmount] = useState(0);
    const [unstakeAmount, setUnstakeAmount] = useState(0);

    const [forcReward, setForcReward] = useState(0);
    const [myForcStaked, setMyForcStaked] = useState(0);
    const [currentRewardPerToken, setCurrentRewardPerToken] = useState(0);
    const [totalForcStaked, setTotalForcStaked] = useState(0);
    const [totalForcLocked, setTotalForcLocked] = useState(0);

    useEffect(() => {
        const getLinearStakingValues = async () => {
            try {
                const currentStakingReward = await getCurrentStakingReward();
                setForcReward(Web3.utils.fromWei((currentStakingReward).toString(), "ether"));

                const totalStakedByAddress = await getTotalStakedByAddress();
                setMyForcStaked(Web3.utils.fromWei((totalStakedByAddress).toString(), "ether"));

                const currentStakingRewardPerToken = await getCurrentStakingRewardPerToken();
                setCurrentRewardPerToken(Web3.utils.fromWei((currentStakingRewardPerToken).toString(), "ether"));

                const totalStaked = await getTotalStaked();
                setTotalForcStaked(Web3.utils.fromWei((totalStaked).toString(), "ether"));

                const totalLocked = await getTotalLocked();
                setTotalForcLocked(Web3.utils.fromWei((totalLocked).toString(), "ether"));
            } catch (error) {
                console.error("Error fetching staking values:", error);
            }
        };

        getLinearStakingValues();
    }, []);

    const handleStake = async() => {
        await approveAmount(erc20InvestmentTokens["11155111"].FORC_TOKEN_ADDRESS,
            erc20InvestmentTokens["11155111"].LINEAR_STAKING_ADDRESS,
            stakeAmount,
            18)
        await stake(stakeAmount);
    }

    const handleUnstake = async() => {
        await unstake(unstakeAmount);
    }

    const handleClaim = async() => {
        await claim();
    }


  const StatItem = ({ label, value }) => (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center',
      padding: '3px 0'
    }}>
      <Text type="secondary">{label}:</Text>
      <Text>{value}</Text>
    </div>
  );
  return (
      <Card 
        size="default"
        title="FORC Token Staking"
        style={{ 
          marginTop: 50,
          width: '100%',
          maxWidth: 480,
          boxShadow: '0 4px 12px rgba(0,0,0,0.08)'
        }}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <div>
            <Text strong>Stake Amount</Text>
            <Space.Compact style={{ width: '100%', marginTop: 8 }}>
              <Input 
                placeholder="Enter stake amount"
                value={stakeAmount}
                onChange={(e) => setStakeAmount(e.target.value)}
                type="number"
                min="0"
              />
              <Button type="primary" onClick={() => handleStake(stakeAmount)}>
                Stake
              </Button>
            </Space.Compact>
          </div>
          <div>
            <Text strong>Unstake Amount</Text>
            <Space.Compact style={{ width: '100%', marginTop: 8 }}>
              <Input 
                placeholder="Enter unstake amount"
                value={unstakeAmount}
                onChange={(e) => setUnstakeAmount(e.target.value)}
                type="number"
                min="0"
              />
              <Button type="primary" onClick={() => handleUnstake(unstakeAmount)}>
                Unstake
              </Button>
            </Space.Compact>
          </div>

          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <StatItem label="Current reward" value={forcReward} />
            <StatItem label="Current reward per token" value={currentRewardPerToken} />
            <StatItem label="My FORC staked" value={myForcStaked} />
            <StatItem label="Total FORC staked" value={totalForcStaked} />
            <StatItem label="Total FORC locked" value={totalForcLocked} />
         </Space>
          <Button 
            type="primary"
            block
            icon={<TrophyOutlined />}
            onClick={handleClaim}
          >
            Claim
          </Button>
        </Space>
      </Card>
  );
};

export default ForcToken;