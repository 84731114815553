import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const saveErc20Token = async (tokenData) => {
    try {
        const response = await axios.post(`${apiUrl}/api/erc20Token`, tokenData);
        return response.data;
    } catch (error) {
        console.error('Error saving contributor data:', error);
        throw error;
    }
};

export const getTokensByProjectName = async (projectName) => {
    try {
        const response = await axios.get(`${apiUrl}/api/erc20Token/${encodeURIComponent(projectName)}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching ERC20 tokens:", error);
        throw error;
    }
};


