import React, { useState, useEffect } from 'react'
import { Link} from "react-router-dom";

import Gitbook from '../../assets/icons/footer/gitbook.svg';
import Discord from "../../assets/icons/footer/discord.svg"
import Medium from "../../assets/icons/footer/medium.svg"
import Linkedin from "../../assets/icons/footer/linkedin.svg"
import Twitter from "../../assets/icons/footer/twitter.svg";
import '../../assets/scss/_layout.scss';


const TokenWidget = () => {
    const saleEndDate = new Date("2025-05-15T00:00:00").getTime();

    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date().getTime();
            const difference = saleEndDate - now;

            if (difference > 0) {
                return {
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                    minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                    seconds: Math.floor((difference % (1000 * 60)) / 1000)
                };
            } else {
                return { days: 0, hours: 0, minutes: 0, seconds: 0 };
            }
        };

        setTimeLeft(calculateTimeLeft());

        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [saleEndDate]);

    const formatNumber = (num) => {
        return num < 10 ? `0${num}` : num;
    };

  const socials = [
    { href: "https://discord.com/invite/CgK8PkyDMW", src: Discord, alt: "Discord" },
    { href: "https://forcefi.medium.com", src: Medium, alt: "Medium" },
    { href: "https://www.linkedin.com/company/forcefi/", src: Linkedin, alt: "LinkedIn" },
    { href: "https://twitter.com/Forcefi_io", src: Twitter, alt: "Twitter" },
]   ;

  return (
        <div className="distribution forc">

        <div className="-left">
            {/* <img className="-img" src={distribution} alt=""/> */}
            <div className="-table">
                    <div className="-cell xl header">Token Ticker</div>
                    <div className="-cell xl header b">FORC</div>
                    <div className="-cell xl">Сhain</div>
                    <div className="-cell xl">Base</div>
                    <div className="-cell xl accent">Total Supply</div>
                    <div className="-cell xl accent">1,000,000,000</div>
                    <div className="-cell xl">Listing date</div>
                    <div className="-cell xl">TBA</div>
                    <div className="-cell xl accent">Listing at</div>
                    <div className="-cell xl accent">TBA, Uniswap</div>

            </div>
        </div>
        {/* Right side - Sale countdown */}
        <div className='-container'>
            <div className="token-widget">
                <div className='-title'>
                The <span className="token-widget__highlight">Forcefi</span> token sale will begin soon:
                </div>
                <div className='-timer'>
                    <div className='-item'>
                        <div className='-item-text offset'>{formatNumber(timeLeft.days)}</div>
                        <div className='-item-text s'>{timeLeft.days === 1 ? 'Day' : 'Days'}</div>
                    </div>
                    <div className='-item'>
                        <div className='-item-text offset'>{formatNumber(timeLeft.hours)}</div>
                        <div className='-item-text s'>{timeLeft.hours === 1 ? 'Hour' : 'Hours'}</div>
                    </div>
                    <div className='-item'>
                        <div className='-item-text offset'>{formatNumber(timeLeft.minutes)}</div>
                        <div className='-item-text s'>{timeLeft.minutes === 1 ? 'Min' : 'Mins'}</div>
                    </div>
                    <div className='-item'>
                        <div className='-item-text offset'>{formatNumber(timeLeft.seconds)}</div>
                        <div className='-item-text s'>{timeLeft.seconds === 1 ? 'Second' : 'Seconds'}</div>
                    </div>
                </div>
                <div className='-socials'>
                    {socials.map((social, index) => (
                        <a
                            key={index}
                            className='-item'
                            href={social.href}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img className='-img' src={social.src} alt={social.alt} />
                        </a>
                    ))}
                </div>
                <div className='center'>

                        <Link to='forc-sale'>
                            <button className="-button">
                                View Sale Page
                            </button>
                        </Link>

                </div>
            </div>
            </div>
        </div>
  )
}

export default TokenWidget
