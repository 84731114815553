import axios from "axios";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export async function sendRegistrationNotification (feedback) {
    await axios.post(apiUrl + '/api/sendRegistrationNotification', {
        projectName: feedback.projectName,
        website: feedback.websiteLink,
        description: feedback.projectDescription,
        achievementsRequest: feedback.achievementsRequest,
        challenges: feedback.challenges,
        supportType: feedback.support,
        doxxedProfiles: feedback.doxxedProfiles,
        socialChannels: feedback.socialChannels,
        contactPerson: feedback.contactPerson,
    })
        .then(response => {
        })
}

export const getReceivedNotifications = async (address) => {
    try {
        const response = await axios.get(apiUrl + '/secured/api/getReceivedNotifications', {
            params: { receiptAddress: address }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching notifications', error);
        throw error;
    }
};
