import React from 'react';
import {SaleCampaignStatus} from "../../utils/SaleCampaignStatus";
import {Button, Input, Progress, Radio} from "antd";
import CheckCircle from "../../assets/icons/sale/CheckCircle.svg";
import InvestmentTable from "./InvestmentTable";

function SaleInvestment({
    projectEntry,
    campaignStatus,
    investmentTokensArray,
    saleReleaseContainer,
    campaignClosed,
    isOwner,
    handleInvest,
    handleCloseCampaign,
    handleClaimTokens,
    investmentTabe,
    handleReclaimTokens,
    setInvestmentAmount,
    handleChangeInvestmentToken

}) {
    return (
        <>
            { (campaignStatus === SaleCampaignStatus.ACTIVE || campaignStatus === SaleCampaignStatus.PRE_SALE || (campaignStatus === SaleCampaignStatus.SUCCESS && !campaignClosed)) &&
                <div 
                className="-investment-block" 
                style={
                    projectEntry?.background
                      ? { backgroundImage: `url(${projectEntry.background})` }
                      : undefined
                  }
              >
                    <div className={(campaignStatus === SaleCampaignStatus.ACTIVE || (campaignStatus === SaleCampaignStatus.SUCCESS && !campaignClosed)) ? "-select" : "-select blur"}>
                        <div className="-title light" style={{ width: '100%' }}>{} now</div>
                        <div>
                            <div className="-text offset light">Investment Token</div>
                            {investmentTokensArray.length > 0 && (
                                <Radio.Group
                                    style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(80px, 1fr))' }}
                                    size="large"
                                    defaultValue={investmentTokensArray[0]}
                                    autoSize={true}
                                    onChange={handleChangeInvestmentToken}
                                >
                                    {investmentTokensArray.map((token) => (
                                        <Radio.Button key={token} value={token} style={{ textAlign: "center" }}>
                                            {token}
                                        </Radio.Button>
                                    ))}
                                </Radio.Group>
                            )}

                        </div>
                        <div>
                            <div className="-text offset light">Quantity</div>
                            <Input onChange={e => setInvestmentAmount(e.target.value)}/>
                        </div>
                        <Button type="primary" onClick={handleInvest}>Buy it now</Button>

                        <div className="-text light">
                            Introducing our powerful anti-aging facial cream that targets fine lines, wrinkles, and age spots, leaving your skin looking youthful and radiant.
                        </div>
                    </div>
                    <div className="-benefits">
                        <div className="-title s">{projectEntry.title || "Key benefits"}</div>
                        {projectEntry.keyBenefits.map((item, index) => (
                        <div className="-item" key={index}>
                            <img src={CheckCircle} alt="" />
                            <div className="-text">{item}</div>
                        </div>
                    ))}
                    </div>
                </div>
            }
            { investmentTabe.length > 0 &&
                <InvestmentTable initialData={investmentTabe} handleReclaimTokens={handleReclaimTokens}/>
            }
            {campaignStatus === SaleCampaignStatus.SUCCESS &&
                <div className="-claim-container">
                    <div className="-title">Tokens released</div>
                    <div className="-grid">
                        <div className="-progress-wrapper">
                            <Progress percent={saleReleaseContainer.tokensReleased / saleReleaseContainer.totalTokensAllocated * 100} showInfo={false}/>
                        </div>
                        <div className="-text">{saleReleaseContainer.tokensReleased} / {saleReleaseContainer.totalTokensAllocated}</div>
                    </div>
                    <div className="-grid">
                        <div className="-text b">Claimable tokens</div>
                        <div className="-text">{saleReleaseContainer.claimableTokens}</div>
                    </div>
                    <div className="-grid">
                        <div className="-text b">Next release date</div>
                        <div className="-text">{saleReleaseContainer.nextReleaseDate}</div>
                    </div>
                    <div className="-btn">
                        <Button onClick={handleClaimTokens}>Claim Tokens</Button>
                    </div>
                </div>
            }
            {campaignStatus === SaleCampaignStatus.FAILED &&
                <div>
                    <div className="-title">
                        Token Reclaiming
                    </div>
                    <div className="-text">
                        Lorem ipsumThis information will be displayed publicly so be careful what you share.This information will be displayed publicly so be careful what you share.This information will be displayed publicly so be careful what you share.
                    </div>
                    <div className="-center offset">
                        <Button type="primary">Reclaim Tokens</Button>
                    </div>
                </div>

            }

            {campaignStatus === SaleCampaignStatus.SUCCESS && isOwner && !campaignClosed &&
                <div className="-center offset">
                    <Button type="primary" onClick={handleCloseCampaign}>Close campaign</Button>
                </div>
            }
        </>
    );
}

export default SaleInvestment;