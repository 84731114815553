import React from 'react';
import Forcefi from "../assets/icons/forcefi-icon.svg";
import '../assets/scss/_error-page.scss';

export default function Page401() {
    return (
        <div className='page401'>
            <img src={Forcefi} alt="" className="-icon"/>
            <div className="-info-block">
                <div className="-text l">Error 401</div>
                <div className="-text">Resource not found</div>
            </div>
        </div>
    );
}