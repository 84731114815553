import React from 'react';
import Topology from "../../assets/images/home/Topology.svg";
import BurstCircle from "../../assets/images/home/Burst-circle.svg";
import Progress from "../../assets/images/home/Progress.svg";
import Looper from "../../assets/images/home/Looper.svg";

export default function Ambitions() {
    return (
        <div className="futures">
            <div className="-box wide">
                <div>
                    <div className="-title offset">Token launch functionality</div>
                    <div className="-text">Launch your token in a highly customizable way, using your smart contract or ours</div>
                </div>
                <a href="https://docs.forcefi.io/platform-features/token-sales" target="_blank" rel="noreferrer" className="-text b">
                    Explore →
                </a>
                <img className='-img topology' src={Topology} alt=""/>
            </div>
            <div className="-wrapper">
                <div className="-box">
                    <div>
                        <div className="-title">Access to <br/> curators</div>
                        <div className="-text small-width">Connect with KOLs, advisors, and industry experts that ‘curate’ your project</div>
                    </div>
                    <img className='-img top' src={BurstCircle} alt=""/>

                </div>
                <div className="-box">
                    <div>
                        <div className="-title">Long-term incentives</div>
                        <div className="-text">We reward listed projects with token rewards for continuous project growth</div>
                    </div>
                    <img className='-img' src={Progress} alt=""/>

                </div>
            </div>
            <div className="-box ">
                <div>
                    <div className="-title">Advisory & Support</div>
                    <div className="-text small-width">Free consultations, weekly calls, & warm intros from the Forcefi team</div>
                </div>
                <a href="https://docs.forcefi.io/background#web3-bd-and-marketing-are-inefficient" target="_blank" rel="noreferrer" className="-text b">
                    Explore →
                </a>
                <img className='-img looper' src={Looper} alt=""/>

            </div>
        </div>
    );
}