const erc20InvestmentTokens = {
    // SEPOLIA
    11155111: {
        USDT: "0x3253193F7d1D3a1Ce5E27e8783c80890BC330467",
        USDC: "0xaeBA03AE008202EBc571E09Ed006087055e21550",
        DAI: "0xB5305A15b1962eE28035b1D073Fd2e1717D3e720",

        FUNDRAISING_ADDRESS: "0x573e55c2883CbE6BcD8A10C0bb13Cf216F1C961B",
        VESTING_ADDRESS: "0x52Bf0Ba9Fc2C6Bd6708375d05751649D94358022",
        PACKAGE_ADDRESS: "0x1762F248841a72e70dFE098938C1222aA3F61aFF",
        STAKING_ADDRESS: "0xae96b57c5e015c320641675B51B45d6d241288D4",
        FORC_TOKEN_ADDRESS: "0x1e494710ddaF9d297C4DF9d11e6acc94ddA51A15",

        SIMPLE_FACTORY_ADDRESS: "0x16D6A60D9Dd69Ae3Fa32Ba2412E5aacC9C57aB0a",
        PAUSABLE_FACTORY_ADDRESS: "0xd4eab39Bfa1f5548fFDD13Edb56460AD490EA289",

        LZERO_CHAIN_ID: "40161",

        LINEAR_STAKING_ADDRESS: "0x40cBe7D13349A54471db8419d92e0f0dD808Fb48",
        LP_YIELD_STAKING_ADDRESS: "0x83F994520bBf9A3E05eb3355B869fE2a19A93fB2",
        UNISWAP_V3_POSITIONS_MANAGER_ADDRESS: "0x1238536071E1c677A632429e3655c799b22cDA52"
    },

    // ARBITRUM-SEPOLIA
    421614: {
        USDT: "0x83e187439137624303F1e889623a56F01A612854",
        USDC: "0xAd9E891a0F259336927d84a6183C77115f7bF651",
        DAI: "0x31da54BC8465a29e07b3896D53B9b81F3b88dEde",

        FUNDRAISING_ADDRESS: "0xFa4bf1a0CB79A9453eeB663a9bAc9a83Fa49539C",
        VESTING_ADDRESS: "0x79BebF5c295DA7F4Eb0061146a9bc4D962E44843",
        PACKAGE_ADDRESS: "0xb5c00d170F80b658d0086C8F4A8f11f6d2fc3ec5",
        STAKING_ADDRESS: "0x4479f01f05f268eF3C0CD5b813B4bB2992c6Eb2D",

        SIMPLE_FACTORY_ADDRESS: "0xe0349F720c2F225AAF2fDcAaA5030CfA62D3165f",
        PAUSABLE_FACTORY_ADDRESS: "0xc62026377c9455e4E606A38ADC1A9Cf8343Bbf69",

        LZERO_CHAIN_ID: "40231"
    },

    // LINEA-SEPOLIA
    59141: {
        USDT: "0x594819c1907a9c93E0dc780EBA152CFD8405c739",
        USDC: "0x11A6a12e790575559422a056C3e4384C80345E66",
        DAI: "0xD79Bed02BA23b76125fA9F75abF4A940CE9887D8",

        FUNDRAISING_ADDRESS: "0x83F994520bBf9A3E05eb3355B869fE2a19A93fB2",
        VESTING_ADDRESS: "0x794442Ec88440f41924390f8734FF70d67fdA0b1",
        PACKAGE_ADDRESS: "0x811E4d08DA26DeD71023668D91A48F7d7Ab01995",

        SIMPLE_FACTORY_ADDRESS: "0x80fBEF0fb55885DAbfa38F0f3A5bF01A00F96b97",
        PAUSABLE_FACTORY_ADDRESS: "0xd882651a259bbfB7C23d44Db3D4bA95f78d434Df",

        STAKING_ADDRESS: "0xAd9E891a0F259336927d84a6183C77115f7bF651",
    },

    // BASE-SEPOLIA
    84532: {
        USDT: "0x3ec8593F930EA45ea58c968260e6e9FF53FC934f",
        USDC: "0xD79Bed02BA23b76125fA9F75abF4A940CE9887D8",
        DAI: "0x8141E7931F1cE1751c9f457FB81ac8c88eD69eC3",

        FUNDRAISING_ADDRESS: "0x811E4d08DA26DeD71023668D91A48F7d7Ab01995",
        VESTING_ADDRESS: "0xF64f044bD840EAA33ACaDD91ffc26F0609e98878",
        PACKAGE_ADDRESS: "0x36e62D0253d271fAe14DB87310F2C2c8396aE9E0",

        SIMPLE_FACTORY_ADDRESS: "0x52517C4943E316B490deCB59c556F2201613b269",
        PAUSABLE_FACTORY_ADDRESS: "0xAe7c9e8688D1451DE88A6166fC4d99f87F470609",

        STAKING_ADDRESS: "0xd882651a259bbfB7C23d44Db3D4bA95f78d434Df",
        LP_YIELD_STAKING_ADDRESS: "0x60c6e0bF349b3CF7555475d167FF6Dd0F2a8E5e9"
    },

};

export default erc20InvestmentTokens;
