import React, {useEffect, useState} from 'react';
import {message, Modal, Steps} from 'antd';
import "../../assets/scss/_modal.scss";
import {useAccount} from "wagmi";
import Spinner from "../elements/Spinner";
import * as ethers from "ethers";
import {updateListingTierInDb} from "../../controllers/ListingController";
import {approveAmount, getAllowance} from "../../ethers/ERC20Contract";
import {bridgeToken, buyPackage, getChainlinkDataFeedLatestAnswer} from "../../ethers/ForcefiPackage";
import erc20InvestmentTokens from "../../constants/ERC20InvestmentTokens";
import {getReferralAddressByUserAddress} from "../../controllers/UserProfileController";

const { Step } = Steps;

const steps = [
    'Approving funds',
    'Buying package',
];

export default function UpdatePackage(props) {
    const [currentStep, setCurrentStep] = useState(0);

    const { address } = useAccount();
    const [isLoading, setIsLoading] = useState(true);

    const provider = window.ethereum ? new ethers.BrowserProvider(window.ethereum) : null;

    useEffect(() => {
        const handleApprove = async (amountToApprove) => {
            const network = await provider.getNetwork();
            setIsLoading(true);

            await approveAmount(
                erc20InvestmentTokens[network.chainId]["USDT"],
                erc20InvestmentTokens[network.chainId].PACKAGE_ADDRESS,
                amountToApprove
            ).then(async () => {
                const referralAddress = await getReferralAddressByUserAddress(address);

                setCurrentStep(2);
                const tier = await buyPackage(
                    props.companyName,
                    props.packageName,
                    erc20InvestmentTokens[network.chainId]["USDT"],
                    referralAddress.data
                );
                if (tier) {
                    await updateListingTier(tier, props.companyName);
                } else {
                    setIsLoading(false);
                    message.error("Failed to upgrade package");
                    props.close();
                }
            });
        };

        const handleProceedUpdatePackage = async () => {
            const network = await provider.getNetwork();
            const chainlinkPrice = await getChainlinkDataFeedLatestAnswer(erc20InvestmentTokens[network.chainId]["USDT"]);
            const currentTokenAllowance = await getAllowance(
                erc20InvestmentTokens[network.chainId]["USDT"],
                address,
                erc20InvestmentTokens[network.chainId].PACKAGE_ADDRESS
            );

            let tokensToApprove;
            if (props.packageName === "Explorer") {
                tokensToApprove = 750;
            } else if (props.packageName === "Accelerator") {
                tokensToApprove = 2000;
            }

            const nr = (tokensToApprove * Number(chainlinkPrice)).toLocaleString();
            const normalizedTokenAmount = nr.replace(/,/g, '');

            if (Number(currentTokenAllowance) >= tokensToApprove * Number(chainlinkPrice)) {
                setCurrentStep(1);
                const referralAddress = await getReferralAddressByUserAddress(address);

                const tier = await buyPackage(
                    props.companyName,
                    props.packageName,
                    erc20InvestmentTokens[network.chainId]["USDT"],
                    referralAddress.data
                );

                if (tier) {
                    await updateListingTier(tier, props.companyName);
                } else {
                    setIsLoading(false);
                    message.error("Failed to upgrade package");
                    props.close();
                }
            } else {
                handleApprove(normalizedTokenAmount);
            }
        };
        if (props.show) {
            handleProceedUpdatePackage();
        }
        // eslint-disable-next-line
    }, [props.show, address, props.companyName, props.packageName]);

    const handleBridgeToken = async () => {
        const destChainId = 123;
        const gasForDestinationLzReceive = 123;

        await bridgeToken(destChainId, props.companyName, gasForDestinationLzReceive);
    };

    async function updateListingTier(listingTier, companyName) {
        await updateListingTierInDb(listingTier, companyName).then((response) => {
            setIsLoading(false);
            message.success("Successfully upgraded package");
            props.close();
        });
    }

    return (
        <div>
            <Modal
                visible={props.show}
                onCancel={props.close}
                footer={null}
                centered
                width={700}
            >
                    <Steps current={currentStep}>
                        {steps.map((label, index) => (
                            <Step key={index} title={label}  size="small" direction="vertical"/>
                        ))}
                    </Steps>
                {isLoading && <Spinner text='' />}
                <div></div>
            </Modal>
        </div>
    );
}
