import React, { useState } from 'react';
import Papa from 'papaparse';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { isAddress } from 'ethers';

const { Dragger } = Upload;

const CSVUploader = ({ setCsvData }) => {
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleFileUpload = (file) => {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                const data = results.data;
                let isValid = true;
                const errors = [];

                if (!data.length || !data[0].hasOwnProperty('address') || !data[0].hasOwnProperty('amount')) {
                    setErrorMessage('CSV must contain "address" and "amount" columns.');
                    message.error('CSV must contain "address" and "amount" columns.');
                    return;
                }
                data.forEach((row, index) => {
                    const address = row['address']?.trim();
                    const amount = row['amount']?.trim();

                    if (!isAddress(address)) {
                        isValid = false;
                        errors.push(`Invalid address at row ${index + 1}: ${address}`);
                    }

                    if (isNaN(amount) || !Number.isInteger(parseFloat(amount))) {
                        isValid = false;
                        errors.push(`Invalid amount at row ${index + 1}: ${amount}. It must be an number.`);
                    }
                });

                if (!isValid) {
                    setUploadSuccess(false);
                    setErrorMessage(errors.join(' '));
                    setCsvData([]);
                    errors.forEach(error => message.error(error));
                } else {
                    setUploadSuccess(true);
                    setErrorMessage('');
                    message.success('CSV file uploaded successfully!');
                    setCsvData(data);
                }
            },
            error: function (error) {
                setUploadSuccess(false);
                setErrorMessage('Error parsing CSV: ' + error.message);
                message.error('Error parsing CSV: ' + error.message);
            },
        });

        return false;
    };

    return (
        <div>
            <h2>Upload CSV</h2>
            <Dragger
                name="file"
                accept=".csv"
                beforeUpload={handleFileUpload}
                maxCount={1}
                showUploadList={true}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Only .csv files are accepted</p>
            </Dragger>

            {uploadSuccess && <p style={{ color: 'green' }}>CSV file uploaded successfully!</p>}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    );
};

export default CSVUploader;
