
import SIDRegister from "@web3-name-sdk/register";
import * as ethers from "ethers";

export async function registerDomain(label, chainIdx, chainId, mainWalletAddress) {
    // detect provider
    if (window.ethereum) {
        const provider = new ethers.BrowserProvider(window.ethereum)

        // switch to arbitrum one
        await provider.send('wallet_switchEthereumChain', [{ chainId: chainIdx }])
        // connect wallet
        await provider.send('eth_requestAccounts', [])
        // get signer
        const signer = provider.getSigner()
        // get address
        // const address = await signer.getAddress()
        // init SIDRegister
        const register = new SIDRegister({ signer, chainId: chainId })
        // check if available
        // const available = await register.getAvailable(label)
        // get price
        // const price = await register.getRentPrice(label, 1)
        // register for one year
        if (chainId === 1){
            await register.register(label, mainWalletAddress, 1, {
                // wait for commit to be valid, waitTime = 60 in milliseconds
                onCommitSuccess: (waitTime) => {
                    return new Promise(resolve => {
                        setTimeout(resolve, waitTime * 1000)
                    })
                }
            })
        } else {
            await register.register(label, mainWalletAddress, 1, {
                // setPrimaryName: true, // set as primary name, default is false,
                // referrer: 'test.bnb' // referrer domain, default is null
            })
        }
    }
}
