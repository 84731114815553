import React, { useEffect, useState } from 'react';
import { Table, Typography, Tag, Space } from 'antd';
import { 
    WalletOutlined, 
    BlockOutlined, 
    DollarOutlined, 
    RocketOutlined 
} from '@ant-design/icons';
import { useAccount } from 'wagmi';
import { getVestingsByBeneficiaryAddress } from "../../controllers/VestingController";
import { getContributorByAddress } from "../../controllers/ContributorController";
import ShortAddress from '../elements/ShortAddress';

const { Text } = Typography;

export default function MyTokensTable() {
    const { address } = useAccount();
    const [vestingTokens, setVestingTokens] = useState([]);
    // const [saleTokens, setSaleTokens] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTokens = async () => {
            try {
                setLoading(true);
                const beneficiaryVestings = await getVestingsByBeneficiaryAddress(address);
                console.log(beneficiaryVestings.data)
                // const saleTokens = await getContributorByAddress(address)
                // setSaleTokens(saleTokens)
                setVestingTokens(beneficiaryVestings.data);
            } catch (error) {
                console.error('Error fetching vesting tokens:', error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchTokens();
    }, [address]);

    const formatTokenAmount = (amount) => {
        return (Number(amount) / 1e18).toFixed(2);
    };

    
    const columns = [
        {
            title: 'Project',
            dataIndex: 'projectName',
            key: 'projectName',
            render: (text, record) => (
                <Space>
                    {text}
                    <Tag color="blue">{record.chain}</Tag>
                </Space>
            )
        },
        {
            title: 'Token Address',
            dataIndex: 'tokenAddress',
            key: 'tokenAddress',
            render: (address) => <ShortAddress address={address}/>
        },
        {
            title: 'Vesting Index',
            dataIndex: 'vestingIdx',
            key: 'vestingIdx',
            render: (index) => <ShortAddress address={index}/>
        },
        {
            title: 'Total Amount',
            dataIndex: 'tokenAmount',
            key: 'tokenAmount',
            render: (amount) => (
                <Space>
                    <DollarOutlined />
                    <Text>{amount}</Text>
                </Space>
            )
        },
        {
            title: 'Released Tokens',
            dataIndex: 'tokensReleased',
            key: 'releasedTokens',
            render: (released) => (
                <Space>
                    <RocketOutlined />
                    <Text type={released > 0 ? 'success' : 'secondary'}>
                        {formatTokenAmount(released)} tokens
                    </Text>
                </Space>
            )
        }
    ];

    return (
        <Table 
            columns={columns} 
            dataSource={vestingTokens} 
            rowKey="_id"
            loading={loading}
            locale={{
                emptyText: 'No tokens found'
            }}
            pagination={{
                pageSize: 5,
                showSizeChanger: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} tokens`
            }}
        />
    );
}
