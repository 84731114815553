import * as ethers from "ethers";
import { AbiCoder } from "@ethersproject/abi";
import ForcefiPackage from "../abis/ForcefiPackage.json";
import LayerZeroBase from "../abis/LayerZeroBase.json";
import erc20InvestmentTokens from "../constants/ERC20InvestmentTokens";
import ContractFactory from "../abis/ContractFactory.json";

export async function buyPackage (projectName, packageLabel, investmentTokenAddress, referralAddress) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork()

        const packageContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].PACKAGE_ADDRESS, ForcefiPackage, signer);

        let tier;

        const tx = await packageContract.buyPackage(projectName, packageLabel, investmentTokenAddress, referralAddress);

        await tx.wait();

        const specificFilter = packageContract.filters.PackageBought(null, null, selectedAccount);

        const matchedEvents = await packageContract.queryFilter(specificFilter, null, "latest")
        for(const event of matchedEvents){
            const projectNameFromChain = event.args[0];
            if (projectNameFromChain === projectName) {
                tier = event.args[1];
            }
        }

        return tier;

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

// export async function bridgeToken (destChainId, projectName) {
//     try {
//         const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
//         const selectedAccount = accounts[0];
//         const provider = new ethers.BrowserProvider(window.ethereum);
//         const signer = await provider.getSigner(selectedAccount);
//         const network = await provider.getNetwork()
//
//         const layerZeroBaseContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].LAYER_ZERO_BASE_ADDRESS, LayerZeroBase, signer);
//
//         const abiCoder = new AbiCoder();
//         const payloadData = abiCoder.encode(
//             ["string"],
//             [projectName]
//         );
//
//         const fees = await layerZeroBaseContract.estimateFees(
//             destChainId,
//             erc20InvestmentTokens[network.chainId].PACKAGE_ADDRESS,
//             payloadData,
//             false,
//             "0x"
//         )
//
//         const gasForDestinationLzReceive = fees[0]
//
//         const packageContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].PACKAGE_ADDRESS, ForcefiPackage, signer);
//
//         const tx = await packageContract.bridgeToken(destChainId, projectName, gasForDestinationLzReceive, { value: "0" });
//
//         await tx.wait();
//
//     } catch (error) {
//         console.error('Error writing to smart contract:', error);
//     }
// }

export async function bridgeToken (destChainId, projectName) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork()

        const layerZeroBaseContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].LAYER_ZERO_BASE_ADDRESS, LayerZeroBase, signer);

        const abiCoder = new AbiCoder();
        const payloadData = abiCoder.encode(
            ["string"],
            [projectName]
        );

        const fees = await layerZeroBaseContract.estimateFees(
            destChainId,
            erc20InvestmentTokens[network.chainId].PACKAGE_ADDRESS,
            payloadData,
            false,
            "0x"
        )

        const gasForDestinationLzReceive = fees[0]

        const packageContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].PACKAGE_ADDRESS, ForcefiPackage, signer);

        const tx = await packageContract.bridgeToken(destChainId, projectName, gasForDestinationLzReceive, { value: "0" });

        await tx.wait();

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getChainlinkDataFeedLatestAnswer (investmentTokenAddress) {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);

        const network = await provider.getNetwork();

        const erc20TokenContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].PACKAGE_ADDRESS, ForcefiPackage, provider);

        return await erc20TokenContract.getChainlinkDataFeedLatestAnswer(investmentTokenAddress);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

