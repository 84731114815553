import React from 'react';
import { Tooltip } from 'antd';
import Skills from "./Skills";
import Avatar from "./Avatar";
import Description from "./Description";

export default function CuratorsCard ({curator, hasCurratorAccess, handleMatchCurator= () =>console.log(""), matchEnable=true }) {
    return (
        <div className='curator'>
            {hasCurratorAccess && matchEnable &&
                <Tooltip title="Matching allows to start communicating with telegram" placement="right">
                    <div className="-match-btn" onClick={() => handleMatchCurator(curator.name, curator.walletAddress, curator.projectMatches)}>Match</div>
                </Tooltip>
            }
            <div className="-user">
                <Avatar name={curator.name} img={curator.img} socials={curator} />
            </div>
            <Description title='Description' text={curator.aboutme} />
            <div className="-expertise-container">
                <Skills roles={curator.roles} />
            </div>
        </div>
    );
}
