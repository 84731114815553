import React from 'react';
import { Modal } from 'antd';
import Spinner from '../elements/Spinner';
import "../../assets/scss/_modal.scss";

export default function LoadingModal(props) {
    return (
        <Modal
            visible={props.show}
            footer={null}
            closable={false}
            width={600}
            centered
        >
            <div className='modal-content'>
                <Spinner text={props.text} />
            </div>
        </Modal>
    );
}
