// tokenHandler.js
import * as ethers from "ethers";
import ERC721 from "../abis/ERC721.json";

export async function approveNft (erc20Address, spender, nftIdx) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);

        const erc721Contract = new ethers.Contract(erc20Address, ERC721, signer);

        const tx = await erc721Contract.approve(spender, nftIdx);

        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
        return error;
    }
};
