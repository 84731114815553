import React, {useState} from "react";
import UpdatePackage from "../modals/UpdatePackage";
import "../../assets/scss/_components.scss";
import plans from "../../json/plans"
import YES from "../../assets/icons/home/Yes.svg";
import NO from "../../assets/icons/home/No.svg";

export default function Packages({getDfoCompanyData, companyName, listingTier}) {

    const[packageName, setPackageName] = useState("")
    const[tokenAmountForApprove, setTokenAmountForApprove] = useState (750);
    const [updatePackageModal, setUpdatePackageModal] = useState(false);

    const handleClosePackageModal = () => {
        setPackageName("")
        setTokenAmountForApprove(750.1);
        setUpdatePackageModal(false)
        getDfoCompanyData()
    }

    const handleBuyExplorerPackage = () => {
        setPackageName("Explorer")
        setTokenAmountForApprove(750.1);
        setUpdatePackageModal(true);
    }
    const handleBuy = id => {
        if (id === "explorer") handleBuyExplorerPackage();
        else if (id === "accelerator") handleBuyAcceleratorPackage();
    }

    const handleBuyAcceleratorPackage = () => {
        setPackageName("Accelerator")
        setTokenAmountForApprove(2000.1)
        setUpdatePackageModal(true);
    }

    // TODO : read project packages from blockchain
    // useContractRead({
    //     address: process.env.REACT_APP_FORCEFI_PACKAGE_ADDRESS,
    //     abi: ForcefiPackage,
    //     functionName: 'viewProjectPackages',
    //     args: [params.get("id")],
    //     chainId: process.env.REACT_APP_ENV === "PROD" ? 1 : 5,
    //     onSuccess(data) {
    //         console.log(data)
    //         if(data[1] && listingTier !== "Accelerator"){
    //             updateListingTierInDb("Accelerator", params.get("id"))
    //                 .then(() => setListingTier("Accelerator"));
    //         } else if (data[0] && listingTier === "Starter"){
    //             updateListingTierInDb("Explorer", params.get("id"))
    //                 .then(() => setListingTier("Explorer"));
    //         }
    //     }
    // })
    // plans.shift();  // Убираем первый элемент массива
    const filteredPlans = plans.filter((plan) => plan.title !== listingTier && plan.title !== "Starter");

    return (
        <div className="-container packages">
            <UpdatePackage amount={tokenAmountForApprove} packageName={packageName} companyName={companyName} show={updatePackageModal} close={() => handleClosePackageModal()} />
            <div className="plans">
                {filteredPlans.map(plan => (
                <div key={plan.id} className="-card package">
                    <div>
                    <div className="-header">
                        <div className={`-text`}>{plan.title}</div>
                    </div>
                    <div className={`-price `}>{plan.price}</div>
                    <div className={`-benefits-title`}>{plan.benefitsTitle}</div>
                    <div className="-benefits">
                        {plan.benefits.map((benefit, index) => (
                        <div key={index} className="-benefit">
                            <img src={benefit.included ? YES : NO} alt="" />
                            <div className={`-text`}>{benefit.text}</div>
                        </div>
                        ))}
                    </div>
                    </div>
                    <button className='-button explorer' onClick={() => handleBuy(plan.id)}>Upgrade</button>

                </div>
                ))}
            </div>
                <div className="-text s">
                    We accept payments only in Tether USDT (ERC-20) for now. The fee will be charged from your wallet with which you connected and listed the project, so be sure you have enough funds on your wallet to complete the transaction and cover network fees.
                                We will add more payment options soon!

                </div>
           
        </div>
    );
}
