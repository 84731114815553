// tokenHandler.js
import * as ethers from "ethers";
import UniswapV3PositionsManager from "../abis/UniswapV3PositionsManager.json";
import erc20InvestmentTokens from "../constants/ERC20InvestmentTokens";

export async function getLPNftsByAddress () {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);

        const positionMangerContract = new ethers.Contract(erc20InvestmentTokens["11155111"].UNISWAP_V3_POSITIONS_MANAGER_ADDRESS, UniswapV3PositionsManager, signer);

        const nftCount = await positionMangerContract.balanceOf(selectedAccount);

        const nfts = [];
        for (let i = 0; i < nftCount; i++) {
            const tokenId = await positionMangerContract.tokenOfOwnerByIndex(selectedAccount, i);
            nfts.push(tokenId.toString());
        }

        return nfts;
    } catch (error) {
        console.error('Error writing to smart contract:', error);
        return error;
    }
};
