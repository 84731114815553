import React, {useEffect, useState} from 'react';
import "../assets/scss/_components.scss";
import "../assets/scss/_curators.scss";
import "../assets/scss/_search.scss"
import "../assets/scss/_layout.scss"
import "../assets/scss/_user.scss";
import {useAccount} from "wagmi";
import {Pagination, Tag} from 'antd';
import MatchMassageModal from "../components/modals/MatchMassageModal";
import rolesLabels from "../json/rolesLabels";
import {useCuratorsAccess} from "../contexts/CuratorsAccess";
import CuratorsCard from "../components/user/CuratorsCard";
import {useLocation} from "react-router-dom";
import {useAuthenticate} from "../hooks/useAuthenticate";
import {getDfoCompaniesByOwner} from "../controllers/ProjectsController";
import {getCuratorsData, getUserAvatar} from "../controllers/UserProfileController";


const params = Object.keys(rolesLabels).reduce((json, key) => {
    json[key] = false;
    return json;
}, {});
export default function Curators() {
    const location = useLocation();
    const {hasCurratorAccess} = useCuratorsAccess();
    const { address } = useAccount();
    const [filterParams, setFilterParams] = useState(params);
    const { authenticate } = useAuthenticate();
    const paramsKeys = Object.keys(params);

    const [itemsPerPage, setItemsPerPage ] = useState(8);
    const [currentPage, setCurrentPage ] = useState(1);
    const [pageCount, setPageCount ] = useState(1);

    const [curators, setCurators] = useState(null);
    const [loading, setLoading] = useState(true);
    const [newCurators, setNewCurators ] = useState({data: [], filterParams: filterParams});
    const [updateTrigger, setUpdateTrigger ] = useState(0);

    const [cachedImages, setCachedImages ] = useState([]);
    const [companyAddress, setCompanyAddress ] = useState('');

    const [matchMassageModal, setMatchMassageModal ] = useState(false);
    const [currentMatchCurator, setCurrentMatchCurator ] = useState("");
    const [curatorWallet, setCuratorWallet ] = useState("");


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getDfoCompaniesByOwner(address);
                setCompanyAddress(data[0]);
            } catch (error) {
                console.error('Error setting company address:', error);
            }
        };
        fetchData();
    }, [address])

    useEffect(() => {
        const getCuratorsFromDb = async (path, queryParams, params) => {
            try {
                const data = await getCuratorsData(path, params);
                const curatorsFromDb = data.transformedData.map((curator) => ({
                    walletAddress: curator.walletAddress,
                    name: curator.nickName,
                    img: "",
                    aboutme: curator.aboutme,
                    linkedin: curator.linkedin,
                    facebook: curator.facebook,
                    youtube: curator.youtube,
                    twitter: curator.twitter,
                    discord: curator.discord,
                    preferredSocialMedia: curator.preferredSocialMedia,
                    roles: {
                        isFundraisingAdvisory: curator.isFundraisingAdvisory,
                        isStrategicAdvisory: curator.isStrategicAdvisory,
                        isLegalAdvisory: curator.isLegalAdvisory,
                        isTechnicalAdvisory: curator.isTechnicalAdvisory,
                        isFinancialAdvisory: curator.isFinancialAdvisory,
                        isProductAdvisory: curator.isProductAdvisory,
                        isMarketingAdvisory: curator.isMarketingAdvisory,
                        isTokenomicsAdvisory: curator.isTokenomicsAdvisory,
                        isGotoMarketStrategy: curator.isGotoMarketStrategy,
                        isGrowthStrategy: curator.isGrowthStrategy,
                        isListings: curator.isListings,
                        isLiquidity: curator.isLiquidity,
                        isInfrastructure: curator.isInfrastructure,
                        isSmartContracts: curator.isSmartContracts,
                        isNFT: curator.isNFT,
                        isMetaverse: curator.isMetaverse,
                        isGameFi: curator.isGameFi,
                        isMarketingExecution: curator.isMarketingExecution,
                        isPR: curator.isPR,
                        isContentCreation: curator.isContentCreation,
                        isTwitterKOL: curator.isTwitterKOL,
                        isYoutubeKOL: curator.isYoutubeKOL,
                        isInfluencer: curator.isInfluencer,
                        isAmbassador: curator.isAmbassador,
                        isCommunityBuilding: curator.isCommunityBuilding,
                        isCommunityManagement: curator.isCommunityManagement,
                        isDesign: curator.isDesign,
                        isUIUX: curator.isUIUX,
                        isBusinessModel: curator.isBusinessModel,
                        isPitchDeck: curator.isPitchDeck,
                        isWhitepaper: curator.isWhitepaper,
                        isHrRecruiting: curator.isHrRecruiting,
                        isDefi: curator.isDefi,
                        isAi: curator.isAi,
                        isTokenDesign: curator.isTokenDesign
                    }
                }));

                setPageCount(Math.ceil(data.userCount / itemsPerPage));

                setNewCurators({ data: curatorsFromDb, filterParams: queryParams });
                setLoading(false)
                for (let i = 0; i < curatorsFromDb.length; i++) {
                    if (cachedImages[curatorsFromDb[i].walletAddress] === undefined) {
                        const avatarImg = await getUserAvatar(curatorsFromDb[i].walletAddress)
                        curatorsFromDb[i].img = avatarImg.avatarLogo;
                        setNewCurators({ data: curatorsFromDb, filterParams: queryParams });
                        setUpdateTrigger(i);
                    } else {
                        curatorsFromDb[i].img = cachedImages[curatorsFromDb[i].walletAddress];
                        setNewCurators({ data: curatorsFromDb, filterParams: queryParams });
                    }
                }
            } catch (error) {
                console.error("Error processing curators:", error);
            }
        };

        const getCurators = async (itemPerPage, currentPage) => {
            const params = {
                owner: address,
                pageNumber: currentPage,
                pageSize: itemPerPage
            };
            const queryParams = filterParams;

            for (const param in queryParams) {
                if (queryParams.hasOwnProperty(param) && queryParams[param]) {
                    params[param] = queryParams[param];
                }
            }
            const path = hasCurratorAccess ? '/api/getCurators' : '/api/getPublicCurators';

            if ((Number(localStorage.getItem('expirationDate')) - new Date() < 0) && hasCurratorAccess) {
                authenticate(address)
            }
            await getCuratorsFromDb(path, queryParams, params)
        }

        getCurators(itemsPerPage, currentPage);
        // eslint-disable-next-line
    }, [filterParams, itemsPerPage])

    const handleChange = (parameter) => {
        setCurrentPage(1);
        setFilterParams({ ...filterParams, [parameter]: !filterParams[parameter] });
    };

    useEffect(() => {
        if (newCurators.filterParams === filterParams) {
            setCurators(<CuratorsCards data={newCurators.data} />);
        }
    }, [newCurators, updateTrigger, filterParams])

    const handleMatchCurator = (curatorName, curatorWalletAddress) => {
        setCurrentMatchCurator(curatorName);
        setCuratorWallet(curatorWalletAddress);
        setMatchMassageModal(true);
    }

    const handleChangePageSize = (page, pageSize) => {
        setCurrentPage(page);
        if (pageSize !== itemsPerPage) {
            setItemsPerPage(pageSize)
            setCurrentPage(1)
        }
    };

    const showAll = () => {
        setFilterParams(params)
    }

    const CuratorsCards = ({data}) => {
        return (
            <div className="-container">
                {data.map((curator, key) =>
                    <CuratorsCard curator={curator} key={key} hasCurratorAccess={hasCurratorAccess} handleMatchCurator={handleMatchCurator}/> )}
            </div>
        );
    }
    const skeletons = Array(8).fill(null);

    return (
        <div className="main-container">
            <MatchMassageModal show={matchMassageModal} close={() => setMatchMassageModal(false)} curatorWallet={curatorWallet} currentMatchCurator={currentMatchCurator}/>
            {hasCurratorAccess && <>
                <div className="socials">Get to know our Curators! </div>

                <div className="curator-text">Search by Tags</div>
                </>
            }
            <div className='curators'>
                {hasCurratorAccess &&
                    <>
                        <div className="-container">
                            <div className="-filter">
                                {paramsKeys.map( (parameter, key) =>
                                    <Tag className="-tag"                                        key={key}
                                        color={filterParams[parameter] ? 'geekblue' : 'default'}
                                        onClick={() => handleChange(parameter)}
                                    >
                                        {rolesLabels[parameter]}
                                    </Tag>
                                )}
                                <Tag className="-tag" color="geekblue" onClick={() => showAll()}>Show all</Tag>
                            </div>
                        </div>
                    </>
                }
                {loading ?
                <div className="-container">
                    {skeletons.map((_, index) => (
                        <div className="curator" key={index}>
                            <div className="user">
                                <div className="-skeleton image"/>
                                <div className="-skeleton name"/>
                                <div className="-social-container">
                                    <div className="-skeleton icons"/>
                                    <div className="-skeleton icons"/>
                                    <div className="-skeleton icons"/>
                                    <div className="-skeleton icons"/>
                                    <div className="-skeleton icons"/>
                                </div>
                            </div>
                            <div className="-skeleton title"/>
                            <div className="-skeleton text"/>
                            <div className="-skeleton text"/>
                            <div className="-skeleton text"/>
                            <div className="-skeleton text"/>
                        </div>
                    ))}
                </div>
                    :
                    <>
                        {curators}
                    </>
                }
                {!hasCurratorAccess && location.pathname !== '/' &&
                    <div>
                        <br/>
                        <br/>
                        <br/>
                        <h3>In order to see the full list of curators your project needs to have at least "Explorer" listing tier.</h3>
                    </div>
                }
            </div>
            {pageCount > 1 &&
                <div className="pagination">
                    <Pagination
                        current={currentPage}
                        total={pageCount * itemsPerPage}
                        pageSize={itemsPerPage}
                        pageSizeOptions={[8,12,16]}
                        onChange={(page, pageSize) => handleChangePageSize(page, pageSize)}
                    />
                </div>
            }
        </div>
    );
}
