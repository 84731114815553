import React, {useState} from 'react';
import Telegram from "../assets/icons/curators/telegram.svg"
import {message} from "antd";
import axios from "axios";
import '../assets/scss/_notifications.scss';

export default function NotificationsMessage({notification}) {
    const handleUpdateNotification = async (action, senderAddress, receiptAddress, messageText) => {
        await axios.post(process.env.REACT_APP_API_ENDPOINT + '/secured/api/updateNotificationStatus',
            {senderAddress: senderAddress,
                receiptAddress: receiptAddress,
                status: action,
                messageText: messageText})
            .then((response) => {
                message.success("Match " + action)
                setShowActionButtons(false);
            })
    }

    const [showActionButtons, setShowActionButtons] = useState(true);

    return (
        <div className="notification">
            <img src={Telegram} className="-img" alt=""/>
            <div className="-message">
                <div className="-text">{notification.messageText}</div>
                {notification.status === "Pending" && showActionButtons &&
                    <div className="-action">
                        <button
                            className='-button accept'
                            onClick={() => handleUpdateNotification("Accept", notification.senderAddress, notification.receiptAddress, notification.messageText)}
                        >
                            accept
                        </button>
                        <button
                            className='-button reject'
                            onClick={() => handleUpdateNotification("Decline", notification.senderAddress, notification.receiptAddress, notification.messageText)}
                        >
                            decline
                        </button>
                    </div>
                }
                <div className="-timestamp">{new Date(notification.messageDateSent).toLocaleDateString() + " " + new Date(notification.messageDateSent).toLocaleTimeString()}</div>
            </div>
        </div>
    );
}
