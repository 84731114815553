import axios from "axios";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export async function saveUserProfile(address, userData, roles, preferredSocialMedia, base64) {
    await axios.post(apiUrl + '/secured/api/saveUserProfile', {
        walletAddress: address,
        newWalletAddress: "",
        nickName: userData.nickName,
        avatarLogo: base64,
        facebook: userData.facebook,
        discord: userData.discord,
        telegram: userData.telegram,
        twitter: userData.twitter,
        youtube: userData.youtube,
        linkedin: userData.linkedin,
        aboutme: userData.aboutme,
        email: userData.email,
        preferredSocialMedia: preferredSocialMedia,

        isFundraisingAdvisory: roles.isFundraisingAdvisory,
        isStrategicAdvisory: roles.isStrategicAdvisory,
        isLegalAdvisory: roles.isLegalAdvisory,
        isTechnicalAdvisory: roles.isTechnicalAdvisory,
        isFinancialAdvisory: roles.isFinancialAdvisory,
        isProductAdvisory: roles.isProductAdvisory,
        isMarketingAdvisory: roles.isMarketingAdvisory,
        isTokenomicsAdvisory: roles.isTokenomicsAdvisory,
        isGotoMarketStrategy: roles.isGotoMarketStrategy,
        isGrowthStrategy: roles.isGrowthStrategy,
        isListings: roles.isListings,
        isLiquidity: roles.isLiquidity,
        isInfrastructure: roles.isInfrastructure,
        isSmartContracts: roles.isSmartContracts,
        isNFT: roles.isNFT,
        isMetaverse: roles.isMetaverse,
        isGameFi: roles.isGameFi,
        isMarketingExecution: roles.isMarketingExecution,
        isPR: roles.isPR,
        isContentCreation: roles.isContentCreation,
        isTwitterKOL: roles.isTwitterKOL,
        isYoutubeKOL: roles.isYoutubeKOL,
        isInfluencer: roles.isInfluencer,
        isAmbassador: roles.isAmbassador,
        isCommunityBuilding: roles.isCommunityBuilding,
        isCommunityManagement: roles.isCommunityManagement,
        isDesign: roles.isDesign,
        isUIUX: roles.isUIUX,
        isBusinessModel: roles.isBusinessModel,
        isPitchDeck: roles.isPitchDeck,
        isWhitepaper: roles.isWhitepaper,
        isHrRecruiting: roles.isHrRecruiting,
        isDefi: roles.isDefi,
        isTokenDesign: roles.isTokenDesign
    })
        .then(() => {
        });
}

export async function saveCurator(newWalletAddress, walletAddress, userData, roles, preferredSocialMedia, base64, userRole, publicAccess) {
    await axios.post(apiUrl + '/secured/api/saveCurator', {
        newWalletAddress: newWalletAddress,
        walletAddress: walletAddress,
        nickName: userData.nickName,
        avatarLogo: base64,
        facebook: userData.facebook,
        discord: userData.discord,
        telegram: userData.telegram,
        twitter: userData.twitter,
        youtube: userData.youtube,
        linkedin: userData.linkedin,
        aboutme: userData.aboutme,
        email: userData.email,
        preferredSocialMedia: preferredSocialMedia,
        userRole: userRole,

        isFundraisingAdvisory: roles.isFundraisingAdvisory,
        isStrategicAdvisory: roles.isStrategicAdvisory,
        isLegalAdvisory: roles.isLegalAdvisory,
        isTechnicalAdvisory: roles.isTechnicalAdvisory,
        isFinancialAdvisory: roles.isFinancialAdvisory,
        isProductAdvisory: roles.isProductAdvisory,
        isMarketingAdvisory: roles.isMarketingAdvisory,
        isTokenomicsAdvisory: roles.isTokenomicsAdvisory,
        isGotoMarketStrategy: roles.isGotoMarketStrategy,
        isGrowthStrategy: roles.isGrowthStrategy,
        isListings: roles.isListings,
        isLiquidity: roles.isLiquidity,
        isInfrastructure: roles.isInfrastructure,
        isSmartContracts: roles.isSmartContracts,
        isNFT: roles.isNFT,
        isMetaverse: roles.isMetaverse,
        isGameFi: roles.isGameFi,
        isMarketingExecution: roles.isMarketingExecution,
        isPR: roles.isPR,
        isContentCreation: roles.isContentCreation,
        isTwitterKOL: roles.isTwitterKOL,
        isYoutubeKOL: roles.isYoutubeKOL,
        isInfluencer: roles.isInfluencer,
        isAmbassador: roles.isAmbassador,
        isCommunityBuilding: roles.isCommunityBuilding,
        isCommunityManagement: roles.isCommunityManagement,
        isDesign: roles.isDesign,
        isUIUX: roles.isUIUX,
        isBusinessModel: roles.isBusinessModel,
        isPitchDeck: roles.isPitchDeck,
        isWhitepaper: roles.isWhitepaper,
        isHrRecruiting: roles.isHrRecruiting,
        isDefi: roles.isDefi,
        isAi: roles.isAi,
        isTokenDesign: roles.isTokenDesign,
        publicAccess: publicAccess
    })
        .then(() => {
        });
}

export async function getUserProfile(address) {
    return await axios.get(apiUrl + '/api/getUserProfile', { params: { walletAddress: address } })
}

export async function getUserProfileByRefLink(refLink) {
    return await axios.get(apiUrl + '/api/getUserProfileByRefLink', { params: { refLink: refLink } })
}

export async function generateRefLink(walletAddress) {
    return await axios.post(apiUrl + '/secured/api/generateRefLink', {walletAddress: walletAddress})
}

export const addFollowCompany = async (apiUrl, companyName, address) => {
    try {
        const response = await axios.post(apiUrl + '/secured/api/addFollowCompany', {
            companyName: companyName,
            walletAddress: address
        });
        return response.data;
    } catch (error) {
        console.error("Error following company:", error);
        throw error;
    }
};

export const removeFollowCompany = async (apiUrl, companyName, address) => {
    try {
        const response = await axios.post(apiUrl + '/secured/api/removeFollowCompany', {
            companyName: companyName,
            walletAddress: address
        });
        return response.data;
    } catch (error) {
        console.error("Error removing follow from company:", error);
        throw error;
    }
};

export const getUserAvatar = async (address) => {
    try {
        const response = await axios.get(apiUrl + '/api/getUserProfileLogo', {
            params: { walletAddress: address }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching user avatar:", error);
        throw error;
    }
};

export const getCuratorsData = async (path, params) => {
    try {
        const response = await axios.get(apiUrl + path, { params });
        return response.data;
    } catch (error) {
        console.error("Error fetching curators data:", error);
        throw error;
    }
};



