import React, {useEffect, useState} from 'react';
import NetworkSelect from "../components/token/NetworkSelect";
import {Button, Checkbox, DatePicker, Divider, Input, Radio, message} from "antd";
import VestingConstructor from "../components/token/VestingConstructor";
import TokenSelect from "../components/token/TokenSelect";
import handleAddFundraising from "../ethers/Fundraising";
import setTimestampFromDate from "../utils/DateConverter";
import {useLocation, useNavigate} from "react-router-dom";
import toSeconds from "../constants/timeConstants";
import erc20InvestmentTokens from "../constants/ERC20InvestmentTokens";
import {approveAmount} from "../ethers/ERC20Contract";
import MetamaskProgress from "../components/modals/MetamaskProgress";
import getCurrentDate from "../utils/getCurrentDate";
import * as ethers from "ethers";
import {z} from 'zod';
import WhitelistedAddresses from "../components/token/WhitelistedAddresses";
import {getUserProfileByRefLink} from "../controllers/UserProfileController";

const { RangePicker } = DatePicker;

const nonEmpty = z.union([
    z.string().nonempty("This field cannot be empty").refine(value => {
        const number = Number(value);
        return !isNaN(number) && number >= 0;
    }, "Must be a non-negative number"),
    z.number().nonnegative("Must be a non-negative number")
]);
const formSchema = z.object({
    minAmount: nonEmpty,
    maxAmount: nonEmpty,
    campaignLimit: nonEmpty,
    usdRate: nonEmpty,
    // rateDelimiter: z.number().min(4, "Rate delimiter must be at least 0"),
    fundraisingStartDate: z.string().nonempty("Fundraising start date is required"),
    fundraisingEndDate: z.string().nonempty("Fundraising end date is required"),
    currency: z.array(z.string().nonempty("Currency is required")).min(1, "At least one currency must be selected"),
    sale: z.enum(["private", "public"], "Sale type is required"),
});

export default function Fundraising() {
    const navigate = useNavigate();

    const [successModal, setSuccessModal] = useState(false);
    const [step, setStep] = useState(0);

    const [selectedToken, setSelectedToken] = useState('');
    const [decimals, setDecimals] = useState('');
    const [showVesting, setShowVesting] = useState(false);

    const location = useLocation();
    const projectName = location.state.projectName;
    const [referallAddress, setReferallAddress] = useState(false);

    const [vestingValues, setVestingValues] = useState([{
        vestingStart: getCurrentDate(),
        vestingPeriod: 0,
        vestingPeriodOption: "day",
        releasePeriod: 0,
        releasePeriodOption: "day",
        lockupPeriod: 0,
        lockupPeriodOption: "day",
        tge: 0,
    }]);
    const [vestingError, setVestingError] = useState(true);
    const [vestingTrigger, setVestingTrigger] = useState(0);
    const [validWhitelistedAddress, setValidWhitelistedAddress] = useState(false);

    useEffect(() => {
        setVestingStart(vestingValues[0].vestingStart);
        setVestingPeriod(vestingValues[0].vestingPeriod * toSeconds[vestingValues[0].vestingPeriodOption]);
        setReleasePeriod(vestingValues[0].releasePeriod * toSeconds[vestingValues[0].releasePeriodOption]);
        setLockupPeriod(vestingValues[0].lockupPeriod * toSeconds[vestingValues[0].lockupPeriodOption]);
        setTgePercent(vestingValues[0].tge);
        // setTokenAllocated(formValues[0].tokenAllocated);
    }, [vestingValues]);

    useEffect(() => {
        async function getReferralAddress() {
            const referral = await getUserProfileByRefLink(localStorage.getItem("referral").replace(/"/g, ''));
            setReferallAddress(referral.data)
        }
        getReferralAddress();
    },[])

    const [vestingStart, setVestingStart] = useState('');
    const [vestingPeriod, setVestingPeriod] = useState('');
    const [releasePeriod, setReleasePeriod] = useState('');
    const [lockupPeriod, setLockupPeriod] = useState('');
    const [tgePercent, setTgePercent] = useState('');

    const [formValues, setFormValues] = useState({
        minAmount: 0,
        maxAmount: 0,
        campaignLimit: 0,
        usdRate: 1,
        usdRateBlockchainValue: 1,
        rateDelimiter: 1,
        currency: ['USDT'],
        fundraisingStartDate: null,
        fundraisingEndDate: null,
        sale: 'public'
    });

    const [validationErrors, setValidationErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'usdRate'){
            if (value < 1){
                setFormValues(prevState => ({
                    ...prevState,
                    'usdRate': value,
                    'usdRateBlockchainValue': 1,
                    'rateDelimiter': 1 / value
                }))
            } else {
                setFormValues(prevState => ({
                    ...prevState,
                    'usdRate': value,
                    'usdRateBlockchainValue': value,
                    'rateDelimiter': 1
                }))
            }
        }

        validateField(name, value);
    };

    const validateField = async (fieldName, value) => {
        try {
            await formSchema.pick({ [fieldName]: true }).parseAsync({ [fieldName]: value });
            setValidationErrors(prevState => ({
                ...prevState,
                [fieldName]: null
            }));
        } catch (error) {
            setValidationErrors(prevState => ({
                ...prevState,
                [fieldName]: error.errors[0]?.message || "Validation error"
            }));
        }
    };

    const handleCreateCampaign = async () => {
        setVestingTrigger(vestingTrigger+1)

        try {
            formSchema.parse(formValues);
        } catch (error) {
            console.log(error)
            if (error.errors) {
                const fieldErrors = {};
                error.errors.forEach(err => {
                    fieldErrors[err.path[0]] = err.message;
                });
                setValidationErrors(fieldErrors);
                return;
            }
        }
        if (formValues.sale === 'private' && !validWhitelistedAddress) return;
        if (vestingError) {
            console.log(vestingError)
            return;
        }
        setSuccessModal(true);
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();
        const approveTx = await approveAmount(selectedToken, erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, formValues.campaignLimit, decimals);
        if (approveTx !== undefined) {
            const errorMsg = approveTx.toString().split('(');
            message.error(errorMsg[0]);
            setStep(0);
        } else {
            setStep(1);

            const whitelistedAddresses = []
            const saleUUID = await handleAddFundraising({
                _label: "",
                _vestingStart: setTimestampFromDate(vestingStart),
                _cliffPeriod: lockupPeriod,
                _vestingPeriod: vestingPeriod,
                _releasePeriod: releasePeriod,
                _tgePercent: tgePercent,
                _totalCampaignLimit: convertToNormalizedValue(formValues.campaignLimit),
                _rate: formValues.usdRateBlockchainValue,
                _rateDelimiter: formValues.rateDelimiter,
                _startDate: setTimestampFromDate(formValues.fundraisingStartDate),
                _endDate: setTimestampFromDate(formValues.fundraisingEndDate),
                _isPrivate: formValues.sale === "private",
                _campaignMinTicketLimit: convertToNormalizedValue(formValues.minAmount),
                _campaignMaxTicketLimit: convertToNormalizedValue(formValues.maxAmount)
            }, getTokenAddresses(formValues.currency, network.chainId),
                referallAddress,
                projectName,
                selectedToken,
                whitelistedAddresses);
            setStep(2);
            navigate('/sale?id=' + saleUUID);
        }

    };

    function getTokenAddresses(tokens, networkId) {
        const tokenMapping = erc20InvestmentTokens[networkId];
        return tokens.map(token => tokenMapping[token]);
    }

    const convertToNormalizedValue = (amount) => {
        const amountInWei = (amount * (10 ** Number(decimals))).toLocaleString();
        return amountInWei.replace(/,/g, '');
    };

    const handleHideVesting = (e) => {
        setShowVesting(e.target.checked);
        setVestingValues([{
            label: "",
            vestingStart: getCurrentDate(),
            vestingPeriod: 0,
            vestingPeriodOption: "day",
            releasePeriod: 0,
            releasePeriodOption: "day",
            lockupPeriod: 0,
            lockupPeriodOption: "day",
            tge: 0,
            tokenAllocated: 0,
            beneficiars: []
        }]);
    };

    return (
        <>
            <MetamaskProgress
                decimals={decimals}
                show={successModal}
                close={() => setSuccessModal(false)}
                step={step}
            />
            <div className="main-container">
                <div className='create-token'>
                    <div>
                        <div className="-title">
                            Fundraising
                        </div>
                        <div className="-text">
                            You can now create a token sale for your project. Follow the steps as indicated below and create a custom plan for the upcoming sale of your tokens. The information you provide here
                            will be shared publicly on your project's dedicated Token Launch page.
                        </div>
                    </div>

                    <div>
                        <div className="-text black offset">Select Applicable Chains</div>
                        <NetworkSelect />
                    </div>
                    <Divider />
                    <TokenSelect selectedToken={selectedToken} setSelectedToken={setSelectedToken} projectName={projectName} setDecimals={setDecimals} />
                    <Checkbox checked={showVesting} onChange={handleHideVesting}>Hide vesting</Checkbox>
                    {!showVesting && <VestingConstructor formValues={vestingValues} setFormValues={setVestingValues} setVestingError={setVestingError} singleVesting={true} trigger={vestingTrigger}/>}
                    <div>
                        <div className="-title s">Token Sale Details</div>
                        <div className="-text">Provide the parameters of your upcoming token sale:</div>
                    </div>

                    <div>
                        <div className="-text black offset">Min amount tokens that can be invested</div>
                        <Input placeholder='0' type="number" name="minAmount" value={formValues.minAmount} onChange={handleInputChange} />
                        {validationErrors.minAmount && <div className="-text error">{validationErrors.minAmount}</div>}
                    </div>
                    <div>
                        <div className="-text black offset">Max amount of tokens that can be invested</div>
                        <Input placeholder='0' type="number" name="maxAmount" value={formValues.maxAmount} onChange={handleInputChange} />
                        {validationErrors.maxAmount && <div className="-text error">{validationErrors.maxAmount}</div>}
                    </div>
                    <div>
                        <div className="-text black offset">Campaign limit</div>
                        <Input placeholder='10000000' type="number" name="campaignLimit" value={formValues.campaignLimit} onChange={handleInputChange} />
                        {validationErrors.campaignLimit && <div className="-text error">{validationErrors.campaignLimit}</div>}
                    </div>
                    <div>
                        <div className="-text black offset">USD Rate </div>
                        <Input placeholder='1' type="number" name="usdRate" value={formValues.usdRate} onChange={handleInputChange} />
                        {validationErrors.usdRate && <div className="-text error">{validationErrors.usdRate}</div>}
                    </div>

                    <div>
                        <div className="-title s">Investors can pay in the following currencies</div>
                        <div className="-text offset">Select the currencies you'd like to get paid in. Note that the transactions will take place on the native chain of your token:</div>
                    </div>
                    <div>
                        <Checkbox.Group onChange={(checkedValues) => handleInputChange({ target: { name: 'currency', value: checkedValues } })} value={formValues.currency}>
                            <Checkbox value="USDT">USDT</Checkbox>
                            <Checkbox value="USDC">USDC</Checkbox>
                            <Checkbox value="DAI">DAI</Checkbox>
                        </Checkbox.Group>
                        {validationErrors.currency && <div className="-text error">{validationErrors.currency}</div>}
                    </div>

                    <div>
                        <div className="-title s">Timeline</div>
                        <div className="-text offset">Select the start and end date of your token sale. if your tokens are sold out prior to the end date, the campaign will automatically conclude:</div>
                        <RangePicker
                            style={{ width: '100%' }}
                            format="YYYY-MM-DD"
                            onChange={(value, dateString) => {
                                handleInputChange({ target: { name: 'fundraisingStartDate', value: dateString[0] } });
                                handleInputChange({ target: { name: 'fundraisingEndDate', value: dateString[1] } });
                            }}
                        />
                        {validationErrors.fundraisingEndDate && <div className="-text error">{validationErrors.fundraisingEndDate}</div>}
                    </div>
                    <div>
                        <div className="-title s">
                            Type of Sale
                        </div>
                        <div className="-text offset">
                            We’ll always let you know about important changes, but you pick what else you want to hear about.
                        </div>
                    </div>
                    <div>
                        <Radio.Group onChange={(e) => handleInputChange({ target: { name: 'sale', value: e.target.value } })} value={formValues.sale}>
                            <Radio value="public">Public Sale</Radio>
                            <Radio value="private">Private Sale</Radio>
                        </Radio.Group>
                        {validationErrors.sale && <div className="-text error">{validationErrors.sale}</div>}
                    </div>
                    {formValues.sale === "private" && <WhitelistedAddresses trigger={vestingTrigger} setValidWhitelistedAddress={setValidWhitelistedAddress}/>}
                    <Divider />
                    <div>
                        <div className="-title s">
                            Confirm Campaign Creation
                        </div>
                        <div className="-text">
                            *Make sure you carefully check each Unlock period to ensure the information you provided is correct.  If everything is correct, you can proceed by clicking on 'Lock'. Do not 'Lock' your vesting contracts if you intend to adjust your tokenomics at a later point in time.
                        </div>
                    </div>
                    <div className="-button-container">
                        <Button type="primary" disabled={selectedToken===''} onClick={handleCreateCampaign}>Create Campaign</Button>
                    </div>
                </div>
            </div>
        </>
    );
}
