import React from 'react';
import "../../assets/scss/_home.scss"
import distribution from "../../assets/images/home/distribution.svg";


export default function Distribution() {
    const data = [
        { title: "22% Strategic", description: "25% TGE, 0 mo cliff, 5 mo vesting", accent: "#FFD700" },
        { title: "5% Private Sale", description: "0% TGE, 1 mo cliff, 12 mo vesting", accent: "#6A3D9A" },
        { title: "3% Public Sale", description: "35% TGE, 0 mo cliff, 4 mo vesting", accent: "#CAB2D6" },
        { title: "20% Staking/Farming", description: "0% TGE, 0 mo cliff, 60 mo vesting", accent: "#0000FF" },
        { title: "10% Team", description: "0% TGE, 18 mo cliff, 24 mo vesting", accent: "#B8B8B8" },
        { title: "10% MM/Exchange Liquidity", description: "50% TGE, 0 mo cliff, 24 mo vesting", accent: "#A6CEE3" },
        { title: "10% Incubation/Curator Fund", description: "0% TGE, 0 mo cliff, 24 mo vesting", accent: "#33A02C" },
        { title: "5% Ecosystem Incentive/Airdrop", description: "0% TGE, 3 mo cliff, 2 mo vesting", accent: "#1F78B4" },
        { title: "5% Advisors", description: "0% TGE, 18 mo cliff, 24 mo vesting", accent: "#FF4500" },
        { title: "5% Marketing", description: "0% TGE, 3 mo cliff, 24 mo vesting", accent: "#FF69B4" },
        { title: "4% Treasury/Reserve", description: "0% TGE, 5 mo cliff, 24 mo vesting", accent: "#808080" },
    ];

    return (
        <div className="distribution">
            <div className="-left">
                <img className="-img" src={distribution} alt=""/>
                <div className="-table">
                        <div className="-cell header">Info</div>
                        <div className="-cell header">Value</div>
                        <div className="-cell">Token Ticker</div>
                        <div className="-cell">FORC</div>
                        <div className="-cell accent">Total Supply</div>
                        <div className="-cell accent">1,000,000,000 FORC</div>
                        <div className="-cell">Blockchain</div>
                        <div className="-cell">Base</div>
                        <div className="-cell accent">Decimals</div>
                        <div className="-cell accent">18</div>
                </div>
            </div>
            <div className="-cards">
                {data.map((item, index) => (
                    <div key={index} className="-card">
                        <div className="-accent" style={{ background: item.accent }}></div>
                        <div>
                            <div className="-title">{item.title}</div>
                            <div className="-text">{item.description}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}