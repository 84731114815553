import React, {useEffect, useState} from 'react';
import {useAuthenticate} from "../../hooks/useAuthenticate";
import {useAccount} from "wagmi";
import Aim from "../../assets/icons/userSettings/aim.svg"
import Avatar from "../../assets/icons/userSettings/avatar.svg"
import Led from "../../assets/icons/userSettings/led.svg"
import Marker from "../../assets/icons/userSettings/marker.svg"
import '../../assets/scss/_cabinet-settings.scss'
import {Input, Button, message} from 'antd';


import {requestUpdateCompanyData} from "../../controllers/ProjectsController";
import FileUpload from '../elements/FileUpload';

export default function ProjectSettings(props) {


    const { authenticate } = useAuthenticate();
    const { address } = useAccount();
    const { TextArea } = Input;

    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;

        setCompanyData((prev) => {
            return {
                ...prev,   // Spread Operator
                [name]: value
            }
        })
    }
    const fixInputLink = (event) => {
        let value = event.target.value;
        value = value.replace(/[:/]/g, '');
        if (!value.match(/^(http|https|htt|htp):\/\//)) {
            value = 'https://' + value;
        }
        value = value.replace(/^((?!https:\/\/)[^:]+)?:\/\//, 'https://');
        const { name } = event.target;
        setCompanyData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const [companyData, setCompanyData] = useState({
        twitter: props.twitter,
        telegram: props.telegram,
        discord: props.discord,
        youtube: props.youtube,
        facebook: props.facebook,
        about: props.about,
        documentation: props.documentation,
        linkedin: props.linkedIn,
        websiteUrl: props.websiteUrl,
        elevatorPitch: props.elevatorPitch
    })
    const [companyLogo, setCompanyLogo] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);

    useEffect(() => {
        setCompanyData({
            twitter: props.twitter,
            telegram: props.telegram,
            discord: props.discord,
            youtube: props.youtube,
            facebook: props.facebook,
            about: props.about,
            documentation: props.documentation,
            elevatorPitch: props.elevatorPitch,
            linkedin: props.linkedIn,
            websiteUrl: props.websiteUrl,
        })
    },[
        props.twitter,
        props.telegram,
        props.discord,
        props.youtube,
        props.facebook,
        props.about,
        props.documentation,
        props.elevatorPitch,
        props.linkedIn,
        props.websiteUrl,
    ])

    const submit = async (e) => {
        // Check if authentication is needed or if the token has expired
        if (shouldAuthenticate()) {
            message.error("Unauthorized. Please sign in, and repeat the action.")
            await authenticate(address);
        }

        e.preventDefault();

        // Get the base64 representation of the image
        const base64 = await getImageBase64();

        // Update company data
        await updateCompanyData(base64);

        // Show success message and update project data
        message.success("Company data updated.");
        props.updatePrjectData();
        props.close();
    };

    const shouldAuthenticate = () => {
        const expirationDate = Number(localStorage.getItem('expirationDate'));
        return expirationDate - new Date() < 0;
    };

    const getImageBase64 = async () => {
        if (companyLogo === null) {
            return props.imgUrl;
        } else {
            return companyLogo;
        }
    };

    const updateCompanyData = async (base64) => {
        try {
            await requestUpdateCompanyData(props.companyName, companyData, base64, backgroundImage);
        } catch (error) {
            console.error("Error updating company data", error);
        }
    };

    const companySocialMediaPlatforms = [
        { name: 'linkedin', label: 'LinkedIn' },
        { name: 'twitter', label: 'Twitter' },
        { name: 'telegram', label: 'Telegram' },
        { name: 'discord', label: 'Discord' },
        { name: 'youtube', label: 'Youtube' },
        { name: 'facebook', label: 'Facebook' },
    ];

    return (
        <div>
            <div className='settings-page'>
                <div className="-grid">
                    <div>
                        <div className='-section'>
                            <img src={Avatar} className='-img' alt=""/>
                            <div className='-text'>Logo</div>
                        </div>
                    </div>
                    <div className="-input-grid">
                        <div className='-text'></div>
                        <FileUpload onFileChange={setCompanyLogo}/>
                    </div>
                </div>
                <div className="-grid">
                    <div>
                        <div className='-section'>
                            <img src={Avatar} className='-img' alt=""/>
                            <div className='-text'>Background image</div>
                        </div>
                    </div>
                    <div className="-input-grid">
                        <div className='-text'></div>
  
                <FileUpload onFileChange={setBackgroundImage} aspectRatio={5} accept=".jpg,.jpeg,.png"/>
                    </div>
                </div>

                <div className="-grid">
                    <div>
                        <div className='-section'>
                            <img src={Marker} className='-img' alt=""/>
                            <div className='-text'>Description</div>
                        </div>
                    </div>
                    <div className="-input-wrapper">
                        <div className='-input-grid'>
                            <div className='-text xs'>Elevator pitch (max 50 characters)</div>
                            <TextArea
                                type="text"
                                name="elevatorPitch"
                                onChange={handleChange}
                                value={companyData.elevatorPitch}
                                maxLength="50"
                            />
                        </div>
                        <div className='-input-grid'>
                            <div className='-text xs'>About <br /> (max 500 characters)</div>
                            <TextArea
                                type="text"
                                name="about"
                                onChange={handleChange}
                                value={companyData.about}
                                maxLength="500"
                            />
                        </div>
                    </div>
                </div>
                <div className="-grid">
                    <div>
                        <div className='-section'>
                            <img src={Led} className='-img' alt=""/>
                            <div className='-text'>Links</div>
                        </div>
                    </div>
                    <div className="-input-wrapper">
                        <div className='-input-grid'>
                            <div className='-text xs'>Documentation</div>
                            <Input
                                type="text"
                                name="documentation"
                                onChange={handleChange}
                                value={companyData.documentation}
                            />
                        </div>

                        <div className='-input-grid'>
                            <div className='-text xs'>Website URL</div>
                            <Input
                                type="text"
                                name="websiteUrl"
                                onChange={handleChange}
                                onBlur={fixInputLink}
                                value={companyData.websiteUrl}
                            />
                        </div>
                    </div>
                </div>
                <div className="-grid disable-border">
                    <div>
                        <div className='-section'>
                            <img src={Aim} className='-img' alt="" />
                            <div className='-text'>Social Media</div>
                        </div>
                    </div>
                    <div className="-input-wrapper">
                        {companySocialMediaPlatforms.map(({ name, label }) => (
                            <div className='-input-grid' key={name}>
                                <div className='-text'>{label}</div>
                                <Input
                                    type="text"
                                    name={name}
                                    onChange={handleChange}
                                    value={companyData[name]}
                                />
                            </div>
                        ))}
                    </div>
                </div>


                <div className="-left">
                    <Button type="primary" onClick={submit}>Save project settings</Button>
                </div>
            </div>
        </div>
    );
}
