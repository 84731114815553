import React, { useState, useEffect } from 'react';
import '../../assets/scss/_notifications.scss';
import axios from 'axios';
import { getProjectCuratorNotifications } from "../../controllers/NotificationController";
import { getDfoCompanyLogo } from "../../controllers/ProjectsController";
import { useAccount } from 'wagmi';

export default function Chat({ contacts, sender }) {
  const [selectedContact, setSelectedContact] = useState(null);
  const [messages, setMessages] = useState({});
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [contactLogos, setContactLogos] = useState({});
  const { address } = useAccount();
  
  const isEthereumAddress = (address) => {
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  };
  
  const isCurator = isEthereumAddress(sender);
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  
  useEffect(() => {
    if (contacts && contacts.length > 0) {
      setSelectedContact(contacts[0]);
    } else {
      setSelectedContact(null);
    }
    
    if (contacts && contacts.length === 0) {
      setIsLoading(false);
    }
  }, [contacts]);
  
  const getContactKey = (contact) => {
    if (isCurator) {
      return contact;
    } else {
      const contactAddress = contact.address;
      return contactAddress;
    }
  };
  
  const getContactValue = (contact) => {
    if (isCurator) {
      return contact;
    } else {
      return contact.address;
    }
  };

  useEffect(() => {
    const fetchLogos = async () => {
      if (!contacts || contacts.length === 0) return;
      
      const logos = {};
      
      for (const contact of contacts) {
        try {
          if (isCurator) {
            const companyName = contact;
            const res = await getDfoCompanyLogo(companyName, "unregistered");
            console.log(res)
            if (res) {
              logos[companyName] = res;
            } 
          } else {
            const curatorAddress = contact.address;
            const response = await axios.get(apiUrl + '/api/getUserProfileLogo', {
              params: { walletAddress: curatorAddress }
            });
            
            if (response.data) {
              if (response.data.logo) {
                logos[curatorAddress] = response.data.logo;
              } else if (response.data.avatarLogo) {
                if (typeof response.data.avatarLogo === 'object' && response.data.avatarLogo.logo) {
                  logos[curatorAddress] = response.data.avatarLogo.logo;
                } else {
                  logos[curatorAddress] = response.data.avatarLogo;
                }
              }
            }
          }
        } catch (error) {
          console.error("Error fetching logo:", error);
        }
      }
      
      setContactLogos(logos);
    };
    
    fetchLogos();
  }, [contacts, isCurator, apiUrl]);

  useEffect(() => {
    const getNotifications = async () => {
      setIsLoading(true);
      try {
        let allNotifications = {};
        
        for (const contact of contacts) {
          const contactKey = getContactKey(contact);
          const contactValue = getContactValue(contact);
          
          console.log(contactValue);
          const notificationsData = isCurator
            ? await getProjectCuratorNotifications(contactValue, sender)
            : await getProjectCuratorNotifications(sender, contactValue);
            
          allNotifications[contactKey] = notificationsData || [];
        }
        
        setMessages(allNotifications);
        setIsLoading(false);
      } catch (error) {
        console.error('Error in fetching notifications:', error);
        setIsLoading(false);
      }
    };

    if (sender && contacts && contacts.length > 0) {
      getNotifications();
    }
  }, [sender, contacts]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !selectedContact) return;

    const contactValue = getContactValue(selectedContact);
    const contactKey = getContactKey(selectedContact);
    
    const newMessageObj = {
      messageText: newMessage,
      projectName: isCurator ? contactValue : sender,
      messageDateSent: new Date().toLocaleTimeString(),
      senderAddress: address
    };

    try {
      setMessages(prevMessages => ({
        ...prevMessages,
        [contactKey]: [
          ...(prevMessages[contactKey] || []),
          newMessageObj
        ]
      }));

      await axios.post(apiUrl + '/secured/api/sendNotification', {
        messageText: newMessage,
        projectName: isCurator ? contactValue : sender,
        receiptAddress: isCurator ? address : contactValue
      });

      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prevMessages => ({
        ...prevMessages,
        [contactKey]: prevMessages[contactKey].filter(msg => msg !== newMessageObj)
      }));
      setNewMessage('');
    }
  };

  const getSelectedContactKey = () => {
    return selectedContact ? getContactKey(selectedContact) : null;
  };
  
  const currentMessages = selectedContact ? messages[getSelectedContactKey()] || [] : [];

  const formatContactName = (contact) => {
    if (isCurator) {
      return contact;
    } else {
      return contact.curatorNickName || `${contact.address.slice(0, 6)}...${contact.address.slice(-4)}`;
    }
  };

  const formatSenderName = (senderAddress) => {
    if (isEthereumAddress(senderAddress)) {
      return `${senderAddress.slice(0, 6)}...${senderAddress.slice(-4)}`;
    }
    return senderAddress;
  };
  
  const getContactLogo = (contact) => {
    const key = isCurator ? contact : contact.address;
    return contactLogos[key] || null;
  };
  
  const renderEmptyState = () => (
    <div className="chat-empty-state">
      <div className="chat-empty-state__content">
        <img 
          src="/assets/images/empty-chat.svg" 
          alt="No messages" 
          className="chat-empty-state__icon" 
          onError={(e) => e.target.src = '/logo192.png'}
        />
        <h3 className="chat-empty-state__title">No messages found</h3>
        <p className="chat-empty-state__message">
          {!contacts || contacts.length === 0 
            ? 'You don\'t have any contacts yet.' 
            : 'Select a contact to start a conversation.'
          }
        </p>
      </div>
    </div>
  );

  const DateFormatter = ({ isoDate, className }) => {
    const formattedDate = new Date(isoDate).toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  
    return <span className={className}>{formattedDate}</span>;
  };

  return (
    <div className="chat">
      {contacts && contacts.length > 0 ? (
        <div className="contacts-list">
          {contacts.map((contact) => {
            const contactKey = isCurator ? contact : contact.address;
            const logoSrc = getContactLogo(contact);
            
            return (
              <div
                key={contactKey}
                className={`contacts-list__item ${selectedContact === contact ? 'contacts-list__item--active' : ''}`}
                onClick={() => setSelectedContact(contact)}
              >
                {logoSrc ? (
                  <img 
                    className="contacts-list__avatar" 
                    src={logoSrc}
                    alt={formatContactName(contact)} 
                  />
                ) : (
                  <img 
                    className="contacts-list__avatar" 
                    src="/logo192.png" 
                    alt={formatContactName(contact)} 
                  />
                )}
                <span className="contacts-list__name">
                  {formatContactName(contact)}
                </span>
              </div>
            );
          })}
        </div>
      ) : null}
      
      <div className="chat-container">
        {isLoading ? (
          <div className="chat-loading">
            <div className="chat-loading__spinner"></div>
            <p className="chat-loading__text">Loading conversations...</p>
          </div>
        ) : contacts && contacts.length > 0 ? (
          <>
            <div className="chat-container__header">
              <h2 className="chat-container__title">
                {selectedContact ? formatContactName(selectedContact) : 'Select a contact'}
              </h2>
              {selectedContact && getContactLogo(selectedContact) && (
                <img 
                  className="chat-container__header-avatar"
                  src={getContactLogo(selectedContact)}
                  alt={formatContactName(selectedContact)}
                />
              )}
            </div>
            {selectedContact ? (
              <>
                <div className="chat-container__messages">
                  {currentMessages.length > 0 ? (
                    currentMessages.map((message, index) => (
                      <div
                        key={`${message.messageDateSent}-${index}`}
                        className={`message ${message.senderAddress === address ? 'message--outgoing' : 'message--incoming'}`}
                      >
                        <div className="message__bubble">
                          <p className="message__text">{message.messageText}</p>
                          <DateFormatter isoDate={message.messageDateSent} className="message__time"/>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="chat-container__empty-messages">
                      <p className="chat-container__empty-message">
                        No messages yet. Start a conversation!
                      </p>
                    </div>
                  )}
                </div>
                <form onSubmit={handleSendMessage} className="chat-input">
                  <div className="chat-input__container">
                    <input
                      type="text"
                      className="chat-input__field"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      placeholder="Type your message..."
                    />
                    <button type="submit" className="chat-input__button">
                      Send
                    </button>
                  </div>
                </form>
              </>
            ) : (
              <div className="chat-container__select-prompt">
                <p>Please select a contact to start messaging</p>
              </div>
            )}
          </>
        ) : (
          renderEmptyState()
        )}
      </div>
    </div>
  );
}