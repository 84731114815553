import React, {useEffect, useState} from 'react';
import "../assets/scss/_sale-page.scss";
import "../assets/scss/_refferral.scss";
import Copy from "../assets/icons/copy.svg";
import "../assets/scss/_layout.scss";
import {Button, Card, Table, message} from "antd";
import {generateRefLink, getUserProfile} from "../controllers/UserProfileController";
import {useAccount} from "wagmi";
import {getReferredCompanies} from "../controllers/ProjectsController";

export default function Referral() {
    const { address } = useAccount();

    const [referralLink, setReferralLink] = useState("");
    const [referredCompanies, setReferredCompanies] = useState([]);
    const [showLink, setShowLink] = useState(null);

    useEffect(() => {
        const getActiveUserProfile = async () => {
            try {
                const res = await getUserProfile(address);
                if (res.data?.refLink) {
                    setReferralLink(process.env.REACT_APP_DOMAIN_ADDRESS + '/?ref=' + res.data.refLink);
                    await getReferrals(res.data.walletAddress);
                    setShowLink(true);
                } else {
                    setShowLink(false);
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };
        getActiveUserProfile();
    }, [address]);

    const getReferrals = async (walletAddress) => {
        try {
            const companies = await getReferredCompanies(walletAddress);
            const transformedCompanies = companies.map((company, index) => ({
                key: String(index + 1),
                project: company.companyName,
                address: company.owner,
                network: company.preferredNetwork,
            }));
            setReferredCompanies(transformedCompanies);
        } catch (error) {
            console.error('Error fetching referred companies:', error);
        }
    };

    const handleGenerateRefLink = async() => {
        await generateRefLink(address)
            .then(res => {
                setReferralLink(process.env.REACT_APP_DOMAIN_ADDRESS + '/?ref=' + res.data);
                message.success("Referral link is generated");
            })
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(referralLink)
            .then(() => {
                message.success('Referral link copied to clipboard!');
            })
            .catch(() => {
                message.error('Failed to copy the link.');
            });
    };

    const referredProjectColumns = [
        {
            title: 'Project',
            dataIndex: 'project',
            key: 'project',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Network',
            dataIndex: 'network',
            key: 'network',
        },
    ];

    const receivedFeesColumns = [
        {
            title: 'Project',
            dataIndex: 'project',
            key: 'project',
        },
        {
            title: 'Amaount',
            dataIndex: 'amaount',
            key: 'amaount',
        },
        {
            title: 'Blockchain',
            dataIndex: 'blockchain',
            key: 'blockchain',
        },
    ];

    const dataSource2 = [
    ];

    return (
        <>
            <div className='back' />
            <div className="main-container">
                <div className="referral">
                    <div className="-title">Referral</div>
                    <div className="-text">
                        Our Web3 platform offers an exclusive referral program that rewards users for bringing new projects to the platform. When you refer a project and it successfully completes a token fundraising campaign, you will earn X% of the total funds raised as a referral bonus. This program incentivizes community growth while providing direct value to those who help expand our ecosystem. Start referring today and earn rewards for contributing to the platform’s success!
                    </div>
                    {showLink === true &&
                        <div className="-ref-link">
                            {referralLink}
                            <img src={Copy} alt="Copy icon" onClick={handleCopy} style={{cursor: 'pointer'}} />
                        </div>
                    }
                    {showLink === false &&
                        <div>
                            <Button type="primary" onClick={handleGenerateRefLink}>Generate referral link</Button>
                        </div>
                    }


                    <Card size="default" title="Referred projects">
                        <Table scroll={{ x: "max-content" }} dataSource={referredCompanies} columns={referredProjectColumns} />
                    </Card>
                    <Card size="default" title="Received fees">
                        <Table scroll={{ x: "max-content" }} dataSource={dataSource2} columns={receivedFeesColumns} />
                    </Card>
                </div>
            </div>
        </>
    );
}
