export const networks = {
    arthera: {
        chainId: `0x${Number(10243).toString(16)}`,
        chainName: "Arthera Testnet",
        nativeCurrency: {
            name: "Arthera",
            symbol: "AA",
            decimals: 18
        },
        rpcUrls: ["https://rpc-test.arthera.net/"],
        blockExplorerUrls: ["https://explorer-test.arthera.net"]
    },
    zetachain: {
        chainId: `0x${Number(7001).toString(16)}`,
        chainName: "Zetachain Testnet",
        nativeCurrency: {
            name: "ZETA",
            symbol: "aZETA",
            decimals: 18
        },
        rpcUrls: ["https://rpc.ankr.com/zetachain_evm_athens_testnet"],
        blockExplorerUrls: ["https://explorer.zetachain.com/"]
    },
    scroll: {
        chainId: `0x${Number(534351).toString(16)}`,
        chainName: "Scroll Testnet",
        nativeCurrency: {
            name: "SCROLL",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://sepolia-rpc.scroll.io"],
        blockExplorerUrls: ["https://sepolia.scrollscan.com"]
    },
    linea: {
        chainId: `0x${Number(59140).toString(16)}`,
        chainName: "Linea Testnet",
        nativeCurrency: {
            name: "LINEA",
            symbol: "LineaETH",
            decimals: 18
        },
        rpcUrls: ["https://rpc.goerli.linea.build"],
        blockExplorerUrls: ["https://explorer.goerli.linea.build"]
    }
};

export const changeNetwork = async ({ networkName, setError }) => {
    try {
        if (!window.ethereum) throw new Error("No crypto wallet found");
        await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
                {
                    ...networks[networkName]
                }
            ]
        });
    } catch (err) {
        setError(err.message);
    }
};

export const factoryAddresses = {
    "10243": {
        factoryAddress: process.env.REACT_APP_DFO_FACTORY_ADDRESS_ARTHERA,
        equityFundraisingAddress: process.env.REACT_APP_ARTHERA_EQUITY_FUNDRAISING_ADDRESS,
        investmentTokenAddress: process.env.REACT_APP_ARTHERA_INVESTMENT_TOKEN_ADDRESS,
        providerUrl: 'https://rpc-test.arthera.net',
        erc20FactoryAddress: process.env.REACT_APP_ERC20_SIMPLE_FACTORY_ADDRESS_ARTHERA,
        isMainnet: false,
        faucet: "https://faucet.arthera.net/",
        chainName: "Arthera testnet",
        projectRegistrationFee: "0"
    },
    "10242": {
        factoryAddress: process.env.REACT_APP_DFO_FACTORY_ADDRESS_ARTHERA,
        equityFundraisingAddress: process.env.REACT_APP_ARTHERA_EQUITY_FUNDRAISING_ADDRESS,
        investmentTokenAddress: process.env.REACT_APP_ARTHERA_INVESTMENT_TOKEN_ADDRESS,
        providerUrl: 'https://rpc.arthera.net/',
        erc20FactoryAddress: process.env.REACT_APP_ERC20_SIMPLE_FACTORY_ADDRESS_ARTHERA,
        isMainnet: true,
        chainName: "Arthera",
        projectRegistrationFee: "0"
    },
    "7001": {
        factoryAddress: process.env.REACT_APP_DFO_FACTORY_ADDRESS_ZETACHAIN,
        equityFundraisingAddress: process.env.REACT_APP_ZETACHAIN_EQUITY_FUNDRAISING_ADDRESS,
        investmentTokenAddress: process.env.REACT_APP_ZETACHAIN_INVESTMENT_TOKEN_ADDRESS,
        providerUrl: 'https://rpc.ankr.com/zetachain_evm_athens_testnet',
        erc20FactoryAddress: process.env.REACT_APP_ERC20_SIMPLE_FACTORY_ADDRESS_ZETACHAIN,
        isMainnet: false,
        faucet: "https://labs.zetachain.com/get-zeta",
        chainName: "Zetachain testnet",
        projectRegistrationFee: "0"
    },
    "534351": {
        factoryAddress: process.env.REACT_APP_DFO_FACTORY_ADDRESS_SCROLL,
        equityFundraisingAddress: process.env.REACT_APP_SCROLL_EQUITY_FUNDRAISING_ADDRESS,
        investmentTokenAddress: process.env.REACT_APP_SCROLL_INVESTMENT_TOKEN_ADDRESS,
        providerUrl: 'https://sepolia-rpc.scroll.io',
        erc20FactoryAddress: process.env.REACT_APP_ERC20_SIMPLE_FACTORY_ADDRESS_SCROLL,
        isMainnet: false,
        faucet: "https://faucet.chainstack.com/scroll-sepolia-testnet-faucet",
        chainName: "Scroll testnet",
        projectRegistrationFee: "0"
    },
    "59140": {
        factoryAddress: process.env.REACT_APP_DFO_FACTORY_ADDRESS_LINEA,
        equityFundraisingAddress: process.env.REACT_APP_LINEA_EQUITY_FUNDRAISING_ADDRESS,
        investmentTokenAddress: process.env.REACT_APP_LINEA_INVESTMENT_TOKEN_ADDRESS,
        providerUrl: 'https://sepolia-rpc.scroll.io',
        erc20FactoryAddress: process.env.REACT_APP_ERC20_SIMPLE_FACTORY_ADDRESS_LINEA,
        isMainnet: false,
        faucet: "https://linea.faucetme.pro/",
        chainName: "Linea testnet"
    },
    "arbitrum": {
        factoryAddress: process.env.REACT_APP_DFO_FACTORY_ADDRESS,
        providerUrl: 'https://arb-mainnet.g.alchemy.com/v2/ZACPnBZrz9rQaDuwu3HVzlSUiDEpCmMo',
        isMainnet: true,
        projectRegistrationFee: "0"
    },
    "arbitrum-goerli": {
        factoryAddress: process.env.REACT_APP_DFO_FACTORY_ADDRESS,
        providerUrl: 'https://arb-goerli.g.alchemy.com/v2/37BgzLFCNFj88GLbH8XZvj9hSu4Yf8bX',
        isMainnet: false,
        projectRegistrationFee: "500000000000000000"
    },
    "bsc": {
        factoryAddress: process.env.REACT_APP_DFO_FACTORY_ADDRESS_BNB,
        providerUrl: 'https://bsc.getblock.io/fbe5a362-77c5-4750-8b87-376a7287b9f3/testnet/',
        isMainnet: true,
        projectRegistrationFee: "3500000000000000000"
    },
    "bsc-testnet": {
        factoryAddress: process.env.REACT_APP_DFO_FACTORY_ADDRESS_BNB,
        providerUrl: 'https://bsc.getblock.io/fbe5a362-77c5-4750-8b87-376a7287b9f3/testnet/',
        isMainnet: true,
        projectRegistrationFee: "0"
    },
    "homestead": {
        factoryAddress: process.env.REACT_APP_DFO_FACTORY_ADDRESS_ETH,
        providerUrl: 'https://eth-mainnet.g.alchemy.com/v2/2-4c8c--moScM8Zb-CkLFMwh7ok2d4hA',
        isMainnet: true,
        projectRegistrationFee: "500000000000000000"
    },
    "goerli": {
        factoryAddress: process.env.REACT_APP_DFO_FACTORY_ADDRESS_ETH,
        providerUrl: 'https://eth-goerli.g.alchemy.com/v2/BroYDKJiSXetrg3JSyYfPyVBiPxgkEPX',
        isMainnet: false,
        projectRegistrationFee: "500000000000000000"
    },
    "maticmum": {
        factoryAddress: process.env.REACT_APP_DFO_FACTORY_ADDRESS_POLYGON,
        providerUrl: 'https://polygon-mainnet.g.alchemy.com/v2/JCI2UQJuVu1Cs458B5PqI3RcRkbYhTv',
        isMainnet: true,
        projectRegistrationFee: "500000000000000000"
    },
    "matic": {
        factoryAddress: process.env.REACT_APP_DFO_FACTORY_ADDRESS_POLYGON,
        providerUrl: 'https://polygon-mainnet.g.alchemy.com/v2/JCI2UQJuVu1Cs458B5PqI3RcRkbYhTv',
        isMainnet: true,
        projectRegistrationFee: "1350000000000000000000"
    },
    "optimism": {
        factoryAddress: process.env.REACT_APP_DFO_FACTORY_ADDRESS_OPTIMISM,
        providerUrl: 'https://opt-mainnet.g.alchemy.com/v2/vh4gOBfnYv2xINtRpmIzAGx29XXUPH4T',
        isMainnet: true,
        projectRegistrationFee: "500000000000000000"
    },
    "optimism-goerli": {
        factoryAddress: process.env.REACT_APP_DFO_FACTORY_ADDRESS_OPTIMISM,
        providerUrl: 'https://opt-goerli.g.alchemy.com/v2/vh4gOBfnYv2xINtRpmIzAGx29XXUPH4T',
        isMainnet: true,
        projectRegistrationFee: "500000000000000000"
    },
    "scroll": {
        factoryAddress: process.env.REACT_APP_DFO_FACTORY_ADDRESS_SCROLL,
        providerUrl: 'https://rpc.scroll.io',
        isMainnet: true,
        projectRegistrationFee: "0"
    },
    "linea": {
        factoryAddress: process.env.REACT_APP_DFO_FACTORY_ADDRESS_LINEA,
        providerUrl: 'https://linea-mainnet.infura.io/v3/',
        isMainnet: true,
        projectRegistrationFee: "0"
    }
};
