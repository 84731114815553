import React from 'react';
import {Tabs} from "antd";
import Web3 from "web3";
import ShortAddress from '../elements/ShortAddress';
const { TabPane } = Tabs

export default function SaleInfo({tokenInformation, campaign, conditions, timeline}) {

    const SpecItem = ({title, value}) => {
        return (
            <div className="-spec-item">
                <div className="-text">{title}</div>
                <div className="-text dark">{value}</div>
            </div>
        )
    }
    return (
        <div className="-info">
            <Tabs defaultActiveKey="1" type="card" horizontalMargin="0" verticalItemMargin="0">
                <TabPane tab="Token Information" key="1">
                    <div className="-tab">
                        <div className="-spec-item">
                            <div className="-text">TOKEN ADDRESS"</div>
                            <div className="-text dark"> 
                                <ShortAddress address={tokenInformation.tokenAddress}/>
                            </div>
                        </div>
                        <SpecItem title="TOKEN TICKER" value={tokenInformation.tokenTicker}/>
                        <SpecItem title="NETWORK" value={tokenInformation.network}/>
                    </div>
                </TabPane>

                <TabPane tab="Sale Timeline" key="2">
                    <div className="-tab">
                        <div className="-text grey">Start Date:</div>
                        <div className="-text b black">{timeline.startDate}</div>
                        <div className="-text grey">End Date:</div>
                        <div className="-text b black">{timeline.endDate}</div>
                    </div>
                </TabPane>

                <TabPane tab="Conditions" key="3">
                    <div className="-tab">
                        <SpecItem
                            title="Minimal Investment"
                            value={conditions.minimalInvestment ? Web3.utils.fromWei(conditions.minimalInvestment.toString(), "ether") : ""}
                        />
                        <SpecItem title="Cliff Period" value={conditions.cliffPeriod / 86400 + " days"}/>
                        <SpecItem title="Vesting Period" value={conditions.vestingPeriod / 86400 + " days"}/>
                        <SpecItem title="Release Period" value={conditions.releasePeriod / 86400 + " days"}/>
                    </div>
                </TabPane>

                <TabPane tab="Campaign Information" key="4">
                    <div className="-tab">
                        <SpecItem
                            title="Hard Cap"
                            value={campaign.hardCap ? Web3.utils.fromWei(campaign.hardCap.toString(), "ether") : ""}
                        />
                        <SpecItem
                            title="Token Allocated"
                            value={campaign.tokenAllocated ? Web3.utils.fromWei(campaign.tokenAllocated.toString(), "ether") : ""}
                        />
                        <SpecItem title="Sale type" value={campaign.saleType}/>
                    </div>
                </TabPane>
            </Tabs>
        </div>
    );
}