import React, {useEffect, useState} from 'react';
import { Table, Button } from 'antd';
import {getIndividualVesting, getProjectVestings, getVestingPlan, releaseVestedTokens} from "../../../ethers/Vesting";
import LoadingModal from "../../modals/LoadingModal";
import ShortAddress from "../../elements/ShortAddress";
import Web3 from "web3";
import AddBeneficiar from "../../modals/AddBeneficiar";

const VestingTable = (props) => {

    const[dataSource, setDataSource] = useState([])
    const[isLoading, setIsLoading] = useState(false)
    const[showAddBenificiar, setShowAddBenificiar] = useState(false)
    const[activeVestingIdx, setActiveVestingIdx] = useState(false)
    const[activeTokenAddress, setActiveTokenAddress] = useState(false)

    useEffect(() => {
        const handleGetVestingData = async () => {
            const vestingData = await getProjectVestings(props.projectName);

            const dataFromBlockchain = [];

            for(const vesting of vestingData) {
                const vestingPlan = await getVestingPlan(vesting);

                const individualVesting = await getIndividualVesting(vesting)
                dataFromBlockchain.push({
                    index: 1,
                    tokenAddress: vestingPlan[0],
                    label: vestingPlan[2],
                    totalTokenAmount: Web3.utils.fromWei(vestingPlan[9].toString(), "ether"),
                    tokenAllocated: Web3.utils.fromWei(vestingPlan[10].toString(), "ether"),
                    vestingIdx: vesting,
                    tokensReleased: Web3.utils.fromWei(individualVesting[1].toString(), "ether"),
                    individualTokensAllocated: Web3.utils.fromWei(individualVesting[0].toString(), "ether")
                })
            }

            setDataSource(dataFromBlockchain)
        }
        handleGetVestingData()
    },[props.projectName])

    const handleReleaseToken = async (vestingIdx) => {
        setIsLoading(true)
        await releaseVestedTokens(vestingIdx);
        setIsLoading(false)
    }

    const columns = [
        {
            title: 'Token address',
            dataIndex: 'tokenAddress',
            key: 'tokenAddress',
            render: (text, record) => (
                <ShortAddress address={record.tokenAddress} />
            ),
        },
        {
            title: 'Vesting label',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Total tokens locked',
            dataIndex: 'totalTokenAmount',
            key: 'totalTokenAmount',
        },
        {
            title: 'Tokens allocated',
            dataIndex: 'tokenAllocated',
            key: 'tokenAllocated',
        },
        {
            title: 'Individual tokens allocated',
            dataIndex: 'individualTokensAllocated',
            key: 'individualTokensAllocated'
        },
        {
            title: 'Individual plan tokens released',
            dataIndex: 'tokensReleased',
            key: 'tokensReleased',
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <div>
                    { props.isAdmin &&
                        <div>
                            <Button onClick={() => {
                                setActiveVestingIdx(record.vestingIdx)
                                setActiveTokenAddress(record.tokenAddress)
                                setShowAddBenificiar(true)
                            }}>
                                Add beneficiars
                            </Button>
                        </div>
                    }
                </div>
            ),
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <div>
                    {
                        record.individualTokensAllocated > 0 &&
                        <Button onClick={() => handleReleaseToken(record.vestingIdx)}>
                            Claim
                        </Button>
                    }
                </div>
            ),
        },
    ];

    return (
        <>
            <AddBeneficiar vestingIdx={activeVestingIdx} tokenAddress={activeTokenAddress} show={showAddBenificiar} close={() => setShowAddBenificiar(false)}/>
            <LoadingModal  show={isLoading} text='Claiming tokens...' />
            <Table
                scroll={{ x: "max-content" }}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                rowKey={(record, index) => index}
            />
        </>
    );
};

export default VestingTable;
