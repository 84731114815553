import { NotificationFilled } from '@ant-design/icons';
import '../../assets/scss/_notifications.scss';

export default function TelegramSubscribe() {
  return (
    <div className="notification-banner">
      <div className="notification-banner__container">
        <div className="notification-banner__content">
          <div className="notification-banner__icon-wrapper">
            <span className="icon-bg">
              <NotificationFilled />
            </span>
            <p className="notification-banner__text">
              <span className="mobile-text">Get Forcefi updates on Telegram!</span>
              <span className="desktop-text">
                Subscribe to Forcefi to receive real-time messages and updates on Telegram
              </span>
            </p>
          </div>
          <a href={`https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_NAME}`} className="-link"  target='_blank'>
            <button className="notification-banner__button" type='button'>
              Subscribe Now
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}