import React, { useState } from 'react';
import { Modal, Button, Input, message } from 'antd';
import axios from 'axios';
import "../../assets/scss/_modal.scss";

export default function MatchMassageModal(props) {
    const [messageText, setMessageText] = useState("");

    const handleChangeMessage = (event) => {
        setMessageText(event.target.value);
    };

    const handleSendMessage = async () => {
        try {
            await axios.post(process.env.REACT_APP_API_ENDPOINT + '/secured/api/sendTelegramNotification', {
                messageText: messageText,
                receiptAddress: props.curatorWallet,
                // TODO: add projectName
                projectName: "ARB4"
            });
            message.success("Invitation successfully sent to " + props.currentMatchCurator);
            props.close();
        } catch (error) {
            message.error("Failed to send invitation");
        }
    };

    return (
        <Modal
            title={'Send an invitation to ' + props.currentMatchCurator}
            visible={props.show}
            onCancel={props.close}
            footer={[
                <Button key="submit" type="primary" onClick={handleSendMessage}>
                    Send
                </Button>
            ]}
            centered
            width={500}
            bodyStyle={{ maxHeight: '60vh', overflowY: 'auto' }}
        >
            <div className="-text"></div>
            <Input.TextArea
                rows={4}
                maxLength={250}
                className='-textarea xl offset'
                value={messageText}
                onChange={handleChangeMessage}
            />
        </Modal>
    );
}
