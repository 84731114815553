import React from 'react';
import "../../assets/scss/_sale-progress.scss";

const ProgressBar = ({ maxValue, currentValue, intermediateValue }) => {
    const intermediatePercentage = (intermediateValue / maxValue) * 100;
    const currentPercentage = (currentValue / maxValue) * 100;
    return (
        <div>
            <div className="info-text top">
                <div className="-start">
                    0
                </div>
                {/*{intermediatePercentage > 0 &&*/}
                {/*    <div className="-start" style={{ width: `${intermediatePercentage}%`, marginLeft: "9px", textAlign: "right" }}>*/}
                {/*        {intermediateValue}*/}
                {/*    </div>*/}
                {/*}*/}
                <div className="-end xl">
                    {currentValue}/{maxValue} USDT
                </div>
            </div>
            <div className="progress-bar">
                <div className="-fill" style={{ width: `${currentPercentage}%` }}></div>
                {intermediatePercentage > 0 && intermediateValue < maxValue &&
                    <div className="-fill intermediate" style={{ width: `${intermediatePercentage}%` }}></div>
                }
            </div>
            <div className="info-text bottom">
                <div className="-start">
                    Start
                </div>
                {intermediatePercentage > 0 && intermediateValue < maxValue &&
                    <div className="-start" style={{ width: `${intermediatePercentage}%`, marginLeft: "35px", textAlign: "right" }}>
                        Soft cap
                    </div>
                }
                <div className="-end">
                    Hard Cap
                </div>
            </div>
        </div>
    );
}

export default ProgressBar;

