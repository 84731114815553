const plansData = [
    {
      id: "starter",
      title: "Starter",
      price: "$0",
      benefitsTitle: "INCLUDED",
      benefits: [
        { text: "Free project listing", included: true },
        { text: "1 free consultation call with Forcefi team", included: true },
        { text: "Access to weekly support and feedback calls", included: true },
        { text: "Educational materials", included: false }
      ],
      buttonType: "-button",
      buttonName: "Get Started"
    },
    {
      id: "explorer",
      title: "Explorer",
      price: "$750",
      benefitsTitle: "INCLUDED",
      label: "BEST CHOICE",
      benefits: [
        { text: "All \"Starter\" benefits", included: true },
        { text: "Access to curators", included: true },
        { text: "Warm introductions with relevant curators", included: true },
        { text: "Co-marketing with Forcefi", included: true }
      ],
      buttonType: "-button explorer",
      buttonName: "Get Started"
    },
    {
      id: "accelerator",
      title: "Accelerator",
      price: "$2000",
      benefitsTitle: "INCLUDED",
      benefits: [
        { text: "All \"Explorer benefits", included: true },
        { text: "Warm introductions to key partners", included: true },
        { text: "Token launch support", included: true },
        { text: "Featured listing for 3 months", included: true }
      ],
      buttonType: "-button",
      buttonName: "Get Started"
    }
];
export default plansData;