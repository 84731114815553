import React, { useState } from "react";
import { message, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import FileImg from "../../assets/icons/FileIcon.svg";

const { Dragger } = Upload;

const FileUpload = ({
  onFileChange,
  accept = ".jpg,.jpeg,.png,.svg",
  placeholderText = "Click or drag file to this area to upload",
  previewText = "Support for single file upload.",
  type = "base64",
  aspectRatio = 1,
}) => {
  const [imagePreview, setImagePreview] = useState(null);

  const handleFileUpload = async (file) => {
    if (type === "base64") {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        setImagePreview(base64String);
        if (onFileChange) {
          onFileChange(base64String);
        }
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(URL.createObjectURL(file));
      onFileChange(file);
    }
  };

  const beforeCrop = (file) => {
    const isValidType = file.type === "image/jpeg" || file.type === "image/png";
    if (!isValidType) {
      message.warning("Cropping is only supported for JPEG and PNG files.");
    }
    return isValidType;
  };

  const renderUploader = () => {
    const uploader = (
      <Dragger
        customRequest={({ file, onSuccess }) => {
          handleFileUpload(file);
          onSuccess();
        }}
        showUploadList={false}
        accept={accept}
      >
        {!imagePreview ? (
          <>
            <div>
              <img src={FileImg} alt="" />
              <div className="-placeholder-text">{placeholderText}</div>
              <div className="-preview-text">{previewText}</div>
            </div>
          </>
        ) : (
          <img
            src={imagePreview}
            style={{ width: "100%", maxHeight: "300px", objectFit: "contain" }}
            alt="Uploaded file preview"
          />
        )}
      </Dragger>
    );

    return uploader;
  };

  return (
    <ImgCrop beforeCrop={beforeCrop} aspect={aspectRatio}>
      {renderUploader()}
    </ImgCrop>
  );
};

export default FileUpload;
