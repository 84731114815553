import React, {useEffect} from 'react';
import "../assets/scss/_home.scss"
import "../assets/scss/_layout.scss"
import "../assets/scss/_slider.scss"
import "../assets/scss/_components.scss"
import {useLocation} from "react-router-dom";
import {setLocal} from "web3modal";
import CuratorSlider from "../components/home/CuratorSlider";
import ConnectButton from "../utils/ConnectButton";
import Roadmap from "../components/home/Roadmap";
import Partners from "../components/home/Partners";
import Distribution from "../components/home/Distribution";
import TokenUntility from "../components/home/TokenUntility";
import Infographic from "../components/home/Infographic";
import Ambitions from "../components/home/Ambitions";
import YES from "../assets/icons/home/Yes.svg";
import NO from "../assets/icons/home/No.svg";
import plans from "../json/plans"

export default function Home() {

    const location = useLocation();
    const params = new URLSearchParams(location.search)

    useEffect(() => {
        if (localStorage.getItem("referral") !== undefined && params.get("ref") !== null){
            setLocal("referral", params.get("ref"))
        }
    })

    return (
        <>
            <div className="forcefi-n">
                <div className='-hero'>

                    <div className="-wrapper">
                        <div className='-text-block'>
                            <div className="-text green">JOIN US TODAY!</div>
                            <div className="-title">Fund and scale your Web3 start-up</div>
                            <div className="-text">Forcefi bridges early-stage projects with contributors and industry pros
                            </div>
                            <ConnectButton buttonType={'-button'} homePageNavigation={true} buttonName={"Get Free Access"}/>
                        </div>
                        <Infographic />
                    </div>
                </div>
                <div className="-container">
                    <div className="-get-access">
                        <div className="-title">Looking for Ways to Supercharge your Idea?</div>
                        <div className="-block">
                            <div className='-text'>Forcefi brings together the best brains of Web3 to support the launch and growth of your project. Get access to opportunities, talent, incentives, and a meaningful network.</div>
                            <ConnectButton buttonType={'-button'} homePageNavigation={true} buttonName={"Get Access Now"}/>
                        </div>
                    </div>
                    <div>
                        <div className="-block-head">
                            <div className="-title">We help ambitious teams realize their vision</div>
                        </div>
                        <Ambitions />
                    </div>

                    <div className="-curators">
                        <div className='-block'>
                            <div className="-title">Work with Curators</div>
                            <div className="-text">Curators review listed projects, offer feedback, and assist teams. Elevate your network, marketing, BD, and traction, by working with the right people. Check our docs for more details.</div>
                            <a href='https://docs.forcefi.io/platform-features/curators' target='_blank' rel="noreferrer">
                                <button className="-button">Learn More</button>
                            </a>
                        </div>
                        <CuratorSlider />
                    </div>
                </div>
                <div className="-container wide">
                    <div className="-block-head">
                        <div className="-title">Get support from the best in the industry</div>
                    </div>
                    <Partners />
                    <div>
                        <div className="-block-head">
                            <div className="-title">Roadmap</div>
                        </div>
                        <Roadmap />
                    </div>
                </div>
                <div className="-container">
                    <div className="-block-head">
                        <div className="-title left">Token Distribution</div>
                    </div>
                    <Distribution />
                    <div className="-block-head">
                        <div className="-title">Token Utility</div>
                        <div className="-title second">Join for free and upgrade based on the needs of your project.</div>
                    </div>
                    <TokenUntility />
                    <div>
                        <div className="-block-head">
                            <div className="-title">Choose a Plan that Works for You</div>
                            <div className="-title second">Join for free and upgrade based on the needs of your project.</div>
                        </div>
                        <div className="plans">
                            {plans.map(plan => (
                                <div key={plan.id} className={`-card ${plan.id === "explorer" ? "explorer" : ""}`}>
                                <div>
                                    <div className="-header">
                                    <div className={`-text ${plan.id}`}>{plan.title}</div>
                                    {plan.label && <div className="-label">{plan.label}</div>}
                                    </div>
                                    <div className={`-price ${plan.id}`}>{plan.price}</div>
                                    <div className={`-benefits-title ${plan.id}`}>{plan.benefitsTitle}</div>
                                    <div className="-benefits">
                                    {plan.benefits.map((benefit, index) => (
                                        <div key={index} className="-benefit">
                                        <img src={benefit.included ? YES : NO} alt="" />
                                        <div className={`-text ${plan.id}`}>{benefit.text}</div>
                                        </div>
                                    ))}
                                    </div>
                                </div>
                                <ConnectButton
                                    buttonType={plan.buttonType}
                                    homePageNavigation={true}
                                    buttonName={plan.buttonName}
                                />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="-join-project">
                        <div className="-title">Ready to Supercharge your Project’s Growth?</div>
                        <ConnectButton buttonType={'-button'} homePageNavigation={true} buttonName={"Join us today!"}/>
                    </div>
                </div>
            </div>
        </>
    );
}
