import React, { useEffect, useState } from 'react';
import { Modal, Input, Button } from 'antd';
import "../../assets/scss/_modal.scss";
import { registerDomain } from "../../functions/registerDomain";
import { useNetwork } from "wagmi";

export default function CreateSpaceIdModal({ companyName, mainWalletAddress, show, close, isCompany = true }) {
    const [spaceIdText, setSpaceIdText] = useState("");
    const [step, setStep] = useState(0);
    const [enableClose, setEnableClose] = useState(true);

    useEffect(() => {
        setSpaceIdText(companyName);
    }, [companyName]);

    const { chain } = useNetwork();

    const spaceIdConfig = {
        arbitrum: [".arb", '0xA4B1', 42161],
        homestead: [".eth", '0x1', 1],
        bsc: [".bnb", '0x38', 56],
        default: ["", "", 0]
    };

    const [clientIdx, clientId] = (chain && spaceIdConfig[chain.network]) || spaceIdConfig.default;

    const handleRegisterDomain = async () => {
        setStep(1);
        setEnableClose(false);
        await registerDomain(spaceIdText.toLowerCase(), clientIdx, clientId, mainWalletAddress);
        // then:
        // set(true);
        // setEnableClose(true);
        // close();
        // setStep(0);
    };

    const handleInputChange = (event) => {
        const value = event.target.value;
        if (/^[a-zA-Z0-9]*$/.test(value)) {
            setSpaceIdText(value);
        }
    };

    return (
        <Modal
            visible={show}
            onCancel={enableClose ? close : null}
            footer={null}
            centered
            width={500}
            bodyStyle={{
                maxHeight: '90vh',
                overflowY: 'auto',
                borderRadius: '5px',
            }}
        >
            <div className='modal-content small-margin'>
                {step === 0 && (
                    <>
                        <div className='-title s'>
                            Give a name for your {isCompany ? "project’s" : "profile’s"} contract
                        </div>
                        <Input
                            type="text"
                            name="documentation"
                            pattern="[a-zA-Z0-9-]*"
                            value={spaceIdText}
                            onChange={handleInputChange}
                            placeholder="Enter name"
                        />
                        <Button type="primary" onClick={handleRegisterDomain}>Save</Button>
                        <div>
                            <div className="-text">
                                Key points why register your {isCompany ? "project" : "profile"} name on the blockchain:
                            </div>
                            <ul>
                                <li className="-list">Establish a unique identity within the decentralized space.</li>
                                <li className="-list">Enhance visibility and recognition among potential investors and partners.</li>
                                <li className="-list">Foster trust and transparency within the Forcefi community.</li>
                                <li className="-list">Simplify transactions and interactions within the Forcefi platform.</li>
                                <li className="-list">
                                    Please note that there are several name extensions such as .arb, .bnb, and .eth (It is suggested to use the one that your project is built on)
                                </li>
                            </ul>
                        </div>
                    </>
                )}
                {step === 1 && (
                    <div className='-loader'>
                        <div className="-text xl">Please wait...</div>
                        <div className="lds-dual-ring"></div>
                    </div>
                )}
            </div>
        </Modal>
    );
}
