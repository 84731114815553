import React, {useEffect, useState} from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay, Pagination, Navigation} from 'swiper/modules';
// Import Swiper styles
import "../../assets/scss/_slider.scss"
import Prev from "../../assets/icons/prev-button.svg";
import Next from "../../assets/icons/next-button.svg";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import axios from "axios";
import CuratorsCard from "../user/CuratorsCard";
import {useAccount} from 'wagmi';

const NextSwiperSlide = () => <img src={Next} alt="" className="next"/>;
const PrevSwiperSlide = () => <img src={Prev} alt="" className="prev"/>;

export default function CuratorSlider() {
    const { address } = useAccount();
    const [slides, setSlides ] = useState([]);
    const [showSwiper, setShowSwiper ] = useState(false);


    useEffect(() => {
        const getCurators = async () => {
            const params = {
                owner: address,
                pageNumber: 1,
                pageSize: 6
            };
            await axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/getPublicCurators',{ params })
                .then(response => {
                    const curatorsFromDb = [];
                    response.data.transformedData.forEach(async (curator) => {
                        curatorsFromDb.push({
                            walletAddress: curator.walletAddress,
                            name: curator.nickName,
                            img: curator.avatarLogo,
                            aboutme : curator.aboutme,
                            linkedin: curator.socialLinks.linkedin,
                            facebook: curator.socialLinks.facebook,
                            youtube: curator.socialLinks.youtube,
                            twitter: curator.socialLinks.twitter,
                            discord: curator.socialLinks.discord,
                            preferredSocialMedia: curator.preferredSocialMedia,
                            roles : curator.advisoryServices
                        })
                    });
                    setSlides(curatorsFromDb.map((curator, key) => (
                        <SwiperSlide className='-item' key={key}>
                            <CuratorsCard curator={curator} matchEnable={false} key={key} hasCurratorAccess={true} />
                        </SwiperSlide>
                    )));
                    setShowSwiper(true);
                })
        }
        getCurators();
    },[address])

    return (
        showSwiper &&
        <div className="slider-container">
            <div className="-desktop">
             <PrevSwiperSlide />
            </div>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                observeParents={true}
                effect={"fade"}
                observer={true}
                loop={true}
                navigation={{
                    nextEl: '.next',
                    prevEl: '.prev',
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                }}

                modules={[Autoplay, Pagination, Navigation]}
                className="slider"
            >
                {slides}
            </Swiper>
            <div className="-desktop">
             <NextSwiperSlide/>
            </div>
        </div>
    );
}
