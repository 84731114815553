import Curators from "./Curators";
import {useCuratorsAccess} from "../contexts/CuratorsAccess";

export default function CuratorsPage() {
    const {hasAccess, isLoading} = useCuratorsAccess();
    return (
        (isLoading && hasAccess === null) ? <div/> : <Curators hasCuratorAcess={hasAccess} />

    );
}
