import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

// Save a new contributor entry
export const saveContributor = async (contributorData) => {
    try {
        const response = await axios.post(`${apiUrl}/contributor`, contributorData);
        return response.data;
    } catch (error) {
        console.error('Error saving contributor data:', error);
        throw error;
    }
};

// Get contributor entries by contributor address
export const getContributorByAddress = async (contributorAddress) => {
    try {
        const response = await axios.get(`${apiUrl}/contributor/${contributorAddress}`);
        return response.data;
    } catch (error) {
        console.error('Error retrieving contributor data:', error);
        throw error;
    }
};
