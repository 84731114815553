import React from 'react';
import ProgressBar from "./ProgressBar";
import Web3 from "web3";
import {Divider} from "antd";

export default function SaleTimer({saleBlockchain, hasCampaignData, campaign, timerTitle, timer}) {
    return (
        <div className="-progress" >
            <div className="-chain">
                {saleBlockchain.toUpperCase()}
            </div>
            {hasCampaignData &&
                <>
                    <div className="-title m">Sale Progress</div>
                    <ProgressBar maxValue={Web3.utils.fromWei(campaign.hardCap.toString(), "ether")}
                                 currentValue={Web3.utils.fromWei(campaign.tokenAllocated.toString(), "ether")}
                                 intermediateValue={Web3.utils.fromWei(campaign.hardCap.toString(), "ether")}/>
                </>
            }
            <div>
                <div className="-title s">Time Left</div>
                <div className="-text">{timerTitle}</div>
            </div>
            <div className="-timer">
                <div className="-block">
                    <div className="-text grey">Days</div>
                    <div className="-title m">{timer.days}</div>
                </div>
                <Divider type="vertical" style={{height: '100%'}}/>
                <div className="-block">
                    <div className="-text grey">Hours</div>
                    <div className="-title m">{timer.hours}</div>
                </div>
                <Divider type="vertical" style={{height: '100%'}}/>
                <div className="-block">
                    <div className="-text grey">Minutes</div>
                    <div className="-title m">{timer.minutes}</div>
                </div>
                <Divider type="vertical" style={{height: '100%'}}/>
                <div className="-block">
                    <div className="-text grey">Seconds</div>
                    <div className="-title m">{timer.seconds}</div>
                </div>
            </div>
        </div>
    );
}