import { useState } from 'react'
import { Card, Button } from 'antd'
import { SwapOutlined } from '@ant-design/icons'
import {useAccount, useSwitchChain} from "wagmi";
import {chainIcons} from "../../utils/chainIcons";
import {bridgeToken} from "../../ethers/ForcefiPackage";


export default function BridgingAccess({ projectName }) {
  const [selectedChain, setSelectedChain] = useState(null);
  const { chains } = useSwitchChain();
  const { chain } = useAccount();

  const handleBridgeAccess = async () => {
    if (selectedChain) {
      await bridgeToken(selectedChain, projectName)
    }
  };

  return (
    <Card className="bridge-card" style={{ maxWidth: '42rem' }}>
      <div style={{ marginTop: -16 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <p style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.45)', textAlign: 'center' }}>
            Bridge your project's access to another blockchain network. This allows your project to operate on multiple chains.
          </p>
              <div className='chain-select center'>
                {chains.filter(c=> c.name !== chain.name).map((chain) =>
                  <div
                    onClick={() => setSelectedChain(chain.id)}
                    className={chain.id === selectedChain ? "-item active" : "-item"}
                  >
                    <img
                      src={chainIcons[chain.name]}
                      alt={chain.name}
                      style={{ width: 20, height: 20, objectFit: 'contain' }}
                    />
                    <span>{chain.name}</span>
                  </div>

                )}
            </div>
                <div className='center'>
              <Button
                type="primary"
                icon={<SwapOutlined />}
                onClick={handleBridgeAccess}
                disabled={!selectedChain}
              >
                Bridge Access
              </Button>
              </div>
            </div>
        </div>
    </Card>
  );
}
