import React from 'react';

const Benefits = () => {
  // Style for heading
  const headingStyle = {
    color: '#14213D',
    fontSize: '18px',
    marginTop: 0,
    marginBottom: '16px',
    fontWeight: 600,
  };
  return (
    <div className="benefits">
      {/* Card 1: Decentralized Incubator */}
      <div className="-card">
        <h3 style={headingStyle}>
          Forcefi is as a decentralized incubator for AI-focused Web3 projects:
        </h3>
        <ul className="-list">
          <li className="-item">
            <span className="-circle"></span>
            200+ Curators support your project in multiple ways
          </li>
          <li className="-item">
            <span className="-circle"></span>
            Intros to partners, ecosystem-relevant projects, and advice on all things AI
          </li>
          <li className="-item">
            <span className="-circle"></span>
            Access to AI-focused startup programs, co-working, and grant support
          </li>
          <li className="-item">
            <span className="-circle"></span>
            Structure complex tokenomic structures, & custom token launches
          </li>
        </ul>
      </div>

      {/* Card 2: Community Events */}
      <div className="-card">
        <h3 style={headingStyle}>
          Forcefi boosts AI projects with community events:
        </h3>
        <ul className="-list">
          <li className="-item">
            <span className="-circle"></span>
            Join webinars & hackathons to learn from AI-Web3 experts
          </li>
          <li className="-item">
            <span className="-circle"></span>
            Network with founders and builders at our AI-focused events
          </li>
          <li className="-item">
            <span className="-circle"></span>
            Share your insights and build your reputation
          </li>
          <li className="-item">
            <span className="-circle"></span>
            Stay ahead with trends and developments
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Benefits;