import React, {useEffect, useState} from 'react';
import {Button, Table} from 'antd';
import { Link } from 'react-router-dom';
import {getFundraisingInstance, getProjectsFundraisings} from "../../../ethers/Fundraising";
import ShortAddress from "../../elements/ShortAddress";
import Web3 from "web3";

const SaleTable = (props) => {

    const[dataSource, setDataSource] = useState([])

    const columns = [
        {
            title: 'Sale Index',
            dataIndex: 'saleIdx',
            key: 'saleIdx',
            render: (text, record) => (
                <ShortAddress address={record.saleIdx} />
            ),
        },
        {
            title: 'Token address',
            dataIndex: 'tokenName',
            key: 'tokenName',
            render: (text, record) => (
                <ShortAddress address={record.tokenName} />
            ),
        },
        {
            title: 'Total token amount',
            dataIndex: 'totalTokenAmount',
            key: 'totalTokenAmount',
        },
        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',
        },
        {
            title: 'Sale start date',
            dataIndex: 'startDate',
            key: 'startDate',
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <Link to={`/sale?id=${record.saleIdx}`}>
                    <Button style={{ width: '100px' }}>
                        Go To Sale
                    </Button>
                </Link>
            ),
        },
    ];

    useEffect(() => {
        const handleGetFundraisings = async () => {
            const fundraisingIdxs = await getProjectsFundraisings(props.projectName, props.adminAddress);

            const dataFromBlockchain = [];
            for(const fundraising of fundraisingIdxs){
                const fundraisingInstance = await getFundraisingInstance(fundraising);
                dataFromBlockchain.push({
                    saleIdx: fundraising,
                    tokenName: fundraisingInstance[12],
                    totalTokenAmount: Web3.utils.fromWei(fundraisingInstance[5].toString(), "ether") ,
                    progress: "Pre-sale",
                    startDate: new Date(Number(fundraisingInstance[4]) * 1000).toDateString()
                })
            }

            setDataSource(dataFromBlockchain)
        }
        handleGetFundraisings()
    },[props.adminAddress, props.projectName])

    return (
        <>
            <Table
                scroll={{ x: "max-content" }}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                rowKey={(record, index) => index}
            />
        </>
    );
};

export default SaleTable;
