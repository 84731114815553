import React from 'react';
import Socials from "../../assets/images/home/info-graphic/social.svg";
import CuratorsImg from "../../assets/images/home/info-graphic/curators.svg";
import Rocket from "../../assets/images/home/info-graphic/rocket.svg";

export default function Infographic() {
    return (
        <div className="info-graphics">
            <div className="-card">
                <img src={Socials} alt="" className="-img"/>
            </div>
            <div className="-cards">
                <div className="-card">
                    <img src={CuratorsImg} alt="" className="-icon"/>
                    <div className="-text">Total curators</div>
                    <div className="-text">200+</div>
                </div>
                <div className="-card">
                    <img src={Rocket} alt="" className="-icon"/>
                    <div className="-text">Total projects</div>
                    <div className="-text">70+</div>
                </div>
            </div>
        </div>
    );
}