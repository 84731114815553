import * as ethers from "ethers";
import Vesting from "../abis/Vesting.json";
import toSeconds from "../constants/timeConstants";
import setTimestampFromDate from "../utils/DateConverter";
import erc20InvestmentTokens from "../constants/ERC20InvestmentTokens";

const handleAddVestingPlan = async (vestingPlanParams, projectName, tokenAddress, decimals) => {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const vestingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].VESTING_ADDRESS, Vesting, signer);

        const vestingPlans = []
        for(const vestingPlan of vestingPlanParams){
            const beneficiarsInVesting = []
            for(const beneficiar of vestingPlan.beneficiars){
                const totalTokenAmountInWei = (beneficiar.amount * (10 ** Number(decimals))).toLocaleString()
                const totalTokenAmountInWeiNormalized = totalTokenAmountInWei.replace(/,/g, '')
                beneficiarsInVesting.push({
                    beneficiarAddress: beneficiar.address,
                    tokenAmount: totalTokenAmountInWeiNormalized
                })
            }
            const totalTokenAmountInWei = (vestingPlan.tokenAllocated * (10 ** Number(decimals))).toLocaleString()
            const totalTokenAmountInWeiNormalized = totalTokenAmountInWei.replace(/,/g, '')
            vestingPlans.push({
                beneficiaries: beneficiarsInVesting,
                vestingPlanLabel: vestingPlan.label,
                saleStart: setTimestampFromDate(vestingPlan.vestingStart),
                cliffPeriod: vestingPlan.lockupPeriod * toSeconds[vestingPlan.lockupPeriodOption],
                vestingPeriod: vestingPlan.vestingPeriod * toSeconds[vestingPlan.vestingPeriodOption],
                releasePeriod: vestingPlan.releasePeriod * toSeconds[vestingPlan.releasePeriodOption],
                tgePercent: vestingPlan.tge,
                totalTokenAmount: totalTokenAmountInWeiNormalized
            })
        }

        const tx = await vestingContract.addVestingPlansBulk(vestingPlans, projectName, tokenAddress);

        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
        return error;
    }
};

export default handleAddVestingPlan;

export async function getProjectVestings (projectName) {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();

        const vestingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].VESTING_ADDRESS, Vesting, provider);

        return await vestingContract.getVestingsByProjectName(projectName);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getIndividualVesting (vestingIdx) {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const network = await provider.getNetwork();

        const vestingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].VESTING_ADDRESS, Vesting, provider);

        return await vestingContract.individualVestings(vestingIdx, selectedAccount);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getVestingPlan (vestingIdx) {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();

        const vestingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].VESTING_ADDRESS, Vesting, provider);

        return await vestingContract.vestingPlans(vestingIdx);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function releaseVestedTokens (vestingIdx) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const vestingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].VESTING_ADDRESS, Vesting, signer);

        const tx = await vestingContract.releaseVestedTokens(vestingIdx);
        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function addVestingBeneficiar (vestingIdx, benificiars) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const vestingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].VESTING_ADDRESS, Vesting, signer);

        const tx = await vestingContract.addVestingBeneficiaries(vestingIdx, benificiars);
        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}
