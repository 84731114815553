import React from 'react';
import {NavLink, Outlet, useLocation, Link} from "react-router-dom";
import '../assets/scss/_layout.scss';
import Gitbook from '../assets/icons/footer/gitbook.svg';
import Header from "./Header";
import Discord from "../assets/icons/footer/discord.svg"
import Medium from "../assets/icons/footer/medium.svg"
import Linkedin from "../assets/icons/footer/linkedin.svg"
import Twitter from "../assets/icons/footer/twitter.svg";
import Logo from "../assets/images/forcefi-logo.svg";

export default function Layout() {
    const location = useLocation();
    function Footer() {
        const socialLinks = [
            { href: "https://www.linkedin.com/company/forcefi/", src: Linkedin, alt: "LinkedIn" },
            { href: "https://twitter.com/Forcefi_io", src: Twitter, alt: "Twitter" },
            { href: "https://discord.com/invite/CgK8PkyDMW", src: Discord, alt: "Discord" },
            { href: "https://forcefi.medium.com", src: Medium, alt: "Medium" },
            { href: "https://docs.forcefi.io/", src: Gitbook, alt: "Gitbook", className: '-link' }
        ];

        return (
            <div className="footer">
                <div className="-container">
                    <div className="-block">
                        <div className="-main">
                            <img src={Logo} alt="" className="-logo"/>
                            <div className="-socials">
                                {socialLinks.map((link, index) => (
                                    <a
                                        key={index}
                                        href={link.href}
                                        target='_blank'
                                        rel="noreferrer"
                                        className={link.className || ''}
                                    >
                                        <img className='-img' alt={link.alt} src={link.src} />
                                    </a>
                                ))}
                            </div>
                        </div>
                        <div className="-link-list">
                            <NavLink to='/curators' className='-link'>
                                <div className="-item">Our curators</div>
                            </NavLink>
                            <NavLink to='/projects' className='-link'>
                                Projects
                            </NavLink>
                            <a href='https://docs.forcefi.io/' className="-link" target='_blank' rel="noreferrer">Documentation</a>
                        </div>
                    </div>
                    <div className="-block second">
                      © {(new Date().getFullYear())} Relume. All rights reserved.
                    </div>
                </div>
            </div>
        );
    }
    function MobileMenu() {
        return (
            <nav className="mobile-menu">
                <div className="-wrapper">
                    <Link
                        to='/projects'
                        className={location.pathname === "/projects/"  ? "-link active" : "-link "}
                    >Projects</Link>
                    <Link
                        to="/curators"
                        className={location.pathname === "/curators/" ? "-link active" : "-link "}
                    >Curators</Link>
                    <Link
                        to="/referral"
                        className={location.pathname === "/referral/"  ? "-link active" : "-link "}
                    >Referral</Link>
                    <Link
                        to="/registration"
                        className={location.pathname === "/registration/"  ? "-link active" : "-link "}
                    >New Project</Link>
                </div>
            </nav>
        );
    }
    return (
        <div className={location.pathname === "/" ? "layout-container":"layout-container bg-white"}>
            <header>
              <Header />
            </header>
            <main id='main-content'>
                <Outlet />
            </main>
            <footer>
                <Footer />
            </footer>
            <MobileMenu />
        </div>
    )
}
