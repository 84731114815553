import React, { useEffect, useState } from 'react';
import { Popover, message, Button, Pagination } from 'antd';
import { BellTwoTone } from '@ant-design/icons';
import '../../assets/scss/_notifications.scss';
import axios from "axios";
import { useAccount } from "wagmi";
import NotificationsMessage from "./NotificationsMessage";
import { useAuthenticate } from "../../hooks/useAuthenticate";
import { shouldAuthenticate } from "../../utils/shouldAuthenticate";
import TelegramSubscribe from './TelegramSubscribe';
import { getDfoCompanyLogo } from "../../controllers/ProjectsController";

export default function Notifications() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { address } = useAccount();
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { authenticate, authStatus } = useAuthenticate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [notificationLogos, setNotificationLogos] = useState({});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // Load logos for all notifications
  const loadLogosForNotifications = async (notificationsData) => {
    const logosMap = {};
    
    for (const notification of notificationsData) {
      const projectName = notification.projectName || 'unregistered';
      
      try {
        // Use projectName if available, otherwise use projectAddress
        const projectIdentifier = notification.projectName || notification.projectAddress || 'unregistered';
        const logo = await getDfoCompanyLogo(projectIdentifier, "unregistered");
        logosMap[notification._id] = logo;
      } catch (error) {
        console.error(`Failed to load logo for ${projectName}:`, error);
        // Set a default logo or null for failed loads
        logosMap[notification._id] = null;
      }
    }
    
    setNotificationLogos(logosMap);
  };

  useEffect(() => {
    const getNotifications = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/secured/api/getReceivedNotifications`, {
          params: { receiptAddress: address },
        });
        const notificationsData = response.data.reverse();
        setNotifications(notificationsData);
        
        // Load logos for all notifications
        await loadLogosForNotifications(notificationsData);
      } catch (error) {
        message.error("Failed to load notifications.");
      }
    };
    
    if (open) getNotifications();
    setIsAuthenticated(shouldAuthenticate);
  }, [open, address, authStatus]);

  const paginatedNotifications = notifications.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div>
      <div onClick={handleClick}>
        <BellTwoTone className="bell-icon" twoToneColor="#1A18FF" />
      </div>
      <Popover
        content={
          <div className="notifications">
            <div className="-header">
              <div className='-title'>Notifications</div>
            </div>
            {isAuthenticated ? (
              <Button style={{ marginTop: '8px' }} htmlType="submit" onClick={() => authenticate(address)}>
                Sign message to get notifications
              </Button>
            ) : notifications.length === 0 ? (
              <div>There are no notifications</div>
            ) : (
              <div>
                {paginatedNotifications.map((notification, index) => (
                  <NotificationsMessage 
                    key={index} 
                    notification={notification} 
                    logo={notificationLogos[notification._id]}
                  />
                ))}
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={notifications.length}
                  onChange={(page, size) => {
                    setCurrentPage(page);
                    setPageSize(size);
                  }}
                  style={{ marginTop: '16px', textAlign: 'center' }}
                />
              </div>
            )}
          </div>
        }
        trigger="click"
        open={open}
        onOpenChange={open ? handleClose : handleClick}
        placement="bottom"
      />
    </div>
  );
}