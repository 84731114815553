import './App.css';
import React, {useEffect} from 'react';
import {Outlet, useLocation} from "react-router-dom";
import axios from "axios";
import {CuratorsAccessProvider} from "./contexts/CuratorsAccess";
import {Navigate, Route, Routes} from "react-router";
import ThankYouWindow from "./pages/ThankYouWindow";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Cabinet from "./pages/Cabinet";
import ProjectPage from "./pages/ProjectPage";
import Projects from "./pages/Projects";
import ErrorBoundary from "./utils/ErrorBoundary";
import Registration from "./pages/Registration";
import Feedback from "./pages/Feedback";
import Page401 from "./pages/Page401";
import {
    arbitrum,
    arbitrumSepolia,
    base,
    baseSepolia,
    bsc,
    linea,
    lineaSepolia,
    mainnet,
    optimism,
    polygon,
    sepolia,
    sonic,
    zkSync
} from 'viem/chains'
import Partners from "./pages/Partners";
import TokenCreation from "./pages/TokenCreation";
import Vesting from "./pages/Vesting";
import Fundraising from "./pages/Fundraising";
import Sale from "./pages/Sale";
import Referral from "./pages/Referral";
import Curators from './pages/Curators';
import {WagmiProvider} from 'wagmi';
import {config} from "./wagmi";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

import {createAppKit} from '@reown/appkit/react'
import {ethersAdapter, metadata, networks, projectId} from './config'
import Staking from './pages/Staking';
import ForcSale from './pages/ForcSale';

let chains;
if (process.env.REACT_APP_ENV === 'DEV'){
    chains = [baseSepolia, sepolia, arbitrumSepolia, lineaSepolia]
} else {
    chains = [mainnet, arbitrum, optimism, zkSync, polygon, bsc, linea, base, sonic]
}

export default function App() {
    const { pathname } = useLocation();
    useEffect(() => {
        document.documentElement.scrollTo({top:0 ,left:0, behavior: "auto"});
    }, [pathname]);

    const ProtectedRoute = ({ element: Element, ...rest }) => {
        return <Outlet />;
    }

    axios.interceptors.request.use(function (config) {
        config.headers.Authorization = localStorage.getItem('authToken');
        return config;
    });

    const queryClient = new QueryClient();

    createAppKit({
        adapters: [ethersAdapter],
        networks,
        metadata,
        projectId,
        features: {
            analytics: true
        }
    })

    return (
        <>
            <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                    <CuratorsAccessProvider>
                    <Routes>
                    <Route path="/gib-email-sire" element={<ThankYouWindow />}/>
                      <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                          <Route element={<ProtectedRoute />}>
                                <Route path='cabinet' element={<Cabinet />} />
                                <Route path='project' element={<ProjectPage />} />
                                <Route path='partners' element={<Partners />} />
                                <Route path='projects' element={<Projects />} />
                                <Route path='projects/:path' element={<Projects />} />
                                <Route path='registration' element={<ErrorBoundary><Registration /></ErrorBoundary>} />
                                <Route path='feedback' element={<Feedback />} />
                                <Route path='curators' element={<Curators />} />
                                <Route path='create-token' element={<TokenCreation />} />
                                <Route path='vesting' element={<Vesting />} />
                                <Route path='staking' element={<Staking />} />
                                <Route path='fundraising' element={<Fundraising />} />
                                <Route path='sale' element={<Sale />} />
                                <Route path='forc-sale' element={<ForcSale />} />
                                <Route path='referral' element={<Referral />} />
                            </Route>
                            <Route path='401' element={<Page401 />} />
                            <Route path='*' element={<Navigate to="/" replace />} />
                        </Route>
                    </Routes>
                        </CuratorsAccessProvider>
                </QueryClientProvider>
            </WagmiProvider>
        </>
    );
}
