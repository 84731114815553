import * as ethers from "ethers";
import Vesting from "../abis/Vesting.json";
import toSeconds from "../constants/timeConstants";
import setTimestampFromDate from "../utils/DateConverter";
import erc20InvestmentTokens from "../constants/ERC20InvestmentTokens";
import {saveErc20Token} from "../controllers/ERC20TokenController";
import {saveVesting, saveVestingBeneficiary, updateVestingBeneficiary} from "../controllers/VestingController";
import ERC20Token from "../abis/ERC20Token.json";

const handleAddVestingPlan = async (vestingPlanParams, projectName, tokenAddress, decimals) => {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const vestingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].VESTING_ADDRESS, Vesting, signer);
        const erc20Contract = new ethers.Contract(tokenAddress, ERC20Token, signer);

        const tokenName = await erc20Contract.name();
        const tokenTicker = await erc20Contract.symbol();

        const vestingPlans = []
        for(const vestingPlan of vestingPlanParams){
            const beneficiarsInVesting = []
            for(const beneficiar of vestingPlan.beneficiars){
                const totalTokenAmountInWei = (beneficiar.amount * (10 ** Number(decimals))).toLocaleString()
                const totalTokenAmountInWeiNormalized = totalTokenAmountInWei.replace(/,/g, '')
                beneficiarsInVesting.push({
                    beneficiarAddress: beneficiar.address,
                    tokenAmount: totalTokenAmountInWeiNormalized
                })
            }
            const totalTokenAmountInWei = (vestingPlan.tokenAllocated * (10 ** Number(decimals))).toLocaleString()
            const totalTokenAmountInWeiNormalized = totalTokenAmountInWei.replace(/,/g, '')
            vestingPlans.push({
                beneficiaries: beneficiarsInVesting,
                vestingPlanLabel: vestingPlan.label,
                saleStart: setTimestampFromDate(vestingPlan.vestingStart),
                cliffPeriod: vestingPlan.lockupPeriod * toSeconds[vestingPlan.lockupPeriodOption],
                vestingPeriod: vestingPlan.vestingPeriod * toSeconds[vestingPlan.vestingPeriodOption],
                releasePeriod: vestingPlan.releasePeriod * toSeconds[vestingPlan.releasePeriodOption],
                tgePercent: vestingPlan.tge,
                totalTokenAmount: totalTokenAmountInWeiNormalized
            })
        }

        vestingContract.on("AddedBeneficiaries", async (beneficiaries, vestingIdx) => {
            console.log("Added beneficiaries req ============================")
            const vestingPlan = await vestingContract.vestingPlans(vestingIdx);

            await saveVesting({
                ownerAddress: selectedAccount,
                projectName: projectName,
                tokenAddress: tokenAddress,
                tokenName: tokenName,
                tokenTicker: tokenTicker,
                properties: {
                    label: vestingPlan.label,
                    saleStart: vestingPlan.saleStart.toString(),
                    cliffPeriod: vestingPlan.cliffPeriod.toString(),
                    vestingPeriod: vestingPlan.vestingPeriod.toString(),
                    releasePeriod: vestingPlan.releasePeriod.toString(),
                    tgePercent: vestingPlan.tgePercent.toString(),
                    totalTokenAmount: vestingPlan.totalTokenAmount.toString()
                },
                chain: network.chainId.toString(),
                vestingIndex: vestingIdx,
            })

            for(const beneficiary of beneficiaries){
                await saveVestingBeneficiary({
                    projectName: projectName,
                    tokenAddress: tokenAddress,
                    tokenName: tokenName,
                    tokenTicker: tokenTicker,
                    chain: network.chainId.toString(),
                    benificiarAddress: beneficiary.beneficiaryAddress,
                    tokenAmount: beneficiary.tokenAmount.toString(),
                    tokensReleased: 0,
                    vestingIdx: vestingIdx,
                })
            }
        });

        const tx = await vestingContract.addVestingPlansBulk(vestingPlans, projectName, tokenAddress);

        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
        return error;
    }
};

export default handleAddVestingPlan;

export async function getProjectVestings (projectName) {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();

        const vestingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].VESTING_ADDRESS, Vesting, provider);

        return await vestingContract.getVestingsByProjectName(projectName);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getIndividualVesting (vestingIdx) {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const network = await provider.getNetwork();

        const vestingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].VESTING_ADDRESS, Vesting, provider);

        return await vestingContract.individualVestings(vestingIdx, selectedAccount);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getVestingPlan (vestingIdx) {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();

        const vestingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].VESTING_ADDRESS, Vesting, provider);

        return await vestingContract.vestingPlans(vestingIdx);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function releaseVestedTokens (vestingIdx) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const vestingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].VESTING_ADDRESS, Vesting, signer);

        vestingContract.on("TokensReleased", async (vestingIdx, beneficiary, amount) => {
            await updateVestingBeneficiary({beneficiary, amount}, vestingIdx)
        });

        const tx = await vestingContract.releaseVestedTokens(vestingIdx);
        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function addVestingBeneficiar (vestingIdx, benificiars, tokenAddress) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const vestingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].VESTING_ADDRESS, Vesting, signer);
        const erc20Contract = new ethers.Contract(tokenAddress, ERC20Token, signer);

        const tokenName = await erc20Contract.name();
        const tokenTicker = await erc20Contract.symbol();

        const vestingPlan = await vestingContract.vestingPlans(vestingIdx);

        vestingContract.on("AddedBeneficiaries", async (beneficiaries, vestingIdx) => {
            for(const beneficiary of beneficiaries){
                await saveVestingBeneficiary({
                    projectName: vestingPlan.projectName,
                    tokenAddress: vestingPlan.tokenAddress,
                    tokenName: tokenName,
                    tokenTicker: tokenTicker,
                    chain: network.chainId.toString(),
                    benificiarAddress: beneficiary.beneficiaryAddress,
                    tokenAmount: beneficiary.tokenAmount.toString(),
                    tokensReleased: 0,
                    vestingIdx: vestingIdx,
                })
            }
        });

        const tx = await vestingContract.addVestingBeneficiaries(vestingIdx, benificiars);
        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}
