import React, { useState } from 'react';
import { Card, Space, Segmented } from 'antd';
import AccessStaking from '../components/staking/AccessStaking';
import ForcStaking from '../components/staking/ForcStaking';
import NFTStaking from '../components/staking/LPYieldStaking';

const Staking = () => {
    const [activeTab, setActiveTab] = useState('FORC Staking');

    return (
        <Card
            size="big"
            title="Staking"
            style={{
                marginTop: 30,
                width: '100%',
                maxWidth: 580,
                boxShadow: '0 4px 12px rgba(0,0,0,0.08)'
            }}
        >
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Segmented
                    block
                    options={['FORC Staking', 'LP Yield Staking', 'Access Staking']}
                    value={activeTab}
                    onChange={setActiveTab}
                />
                <div style={{ marginTop: 16 }}>
                    {activeTab === 'FORC Staking' && <ForcStaking />}
                    {activeTab === 'Access Staking' && <AccessStaking />}
                    {activeTab === 'LP Yield Staking' && <NFTStaking />}
                </div>
            </Space>
        </Card>
    );
};

export default Staking;
