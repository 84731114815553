import React, { useState, useEffect } from 'react';
import { useAccount } from 'wagmi';
import { Modal, Button, Input, message } from 'antd';
import axios from 'axios';
import "../../assets/scss/_modal.scss";
import "../../assets/scss/_company-card.scss";
import { getDfoCompaniesByOwner, getDfoCompanyLogo } from "../../controllers/ProjectsController";

export default function MatchMessageModal(props) {
    const [messageText, setMessageText] = useState("");
    const [myProjects, setMyProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const { address } = useAccount();

    const handleChangeMessage = (event) => {
        setMessageText(event.target.value);
    };

    useEffect(() => {
        handleGetCompanies();
    }, []);

    const handleGetCompanies = async () => {
        try {
            const dfos = await getDfoCompaniesByOwner(address);
            const acceleratorsCompanies = dfos.filter((dfo) => 
                dfo.listingTier === "Accelerator" || dfo.listingTier === "Explorer"
            );

            const companiesWithLogos = await Promise.all(
                acceleratorsCompanies.map(async (company) => {
                    try {
                        const res = await getDfoCompanyLogo(company.companyName, "unregistered");
                        return { ...company, logo: res|| "/logo192.png" };
                    } catch (err) {
                        console.error(`Error fetching logo for ${company.companyName}:`, err);
                        return { ...company, logo: "/logo192.png" };
                    }
                })
            );

            if (companiesWithLogos.length === 1) {
                setSelectedProject(companiesWithLogos[0].companyName);
            } else {
                setMyProjects(companiesWithLogos);
            }
        } catch (error) {
            console.error("Error fetching companies:", error);
        }
    };

    const handleSendMessage = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/secured/api/sendTelegramNotification`, {
                messageText,
                receiptAddress: props.curatorWallet,
                projectName: selectedProject,
            });
            message.success(`Invitation successfully sent to ${props.currentMatchCurator}`);
            props.close();
        } catch (error) {
            message.error("Failed to send invitation");
        }
    };

    const isProjectMatched = (projectName) => {
        const matches = Array.isArray(props.projectMatches) ? props.projectMatches : [];
        return matches.includes(projectName);
    };

    return (
        <Modal
            title={`Send an invitation to ${props.currentMatchCurator}`}
            visible={props.show}
            onCancel={props.close}
            footer={[
                <Button key="submit" type="primary" onClick={handleSendMessage} disabled={!selectedProject}>
                    Send
                </Button>
            ]}
            centered
            width={500}
            bodyStyle={{ maxHeight: '60vh', overflowY: 'auto' }}
        >
            <div className='company-selecet'>
                {myProjects.length > 1 && myProjects.map((item) => (
                    !isProjectMatched(item.companyName) && (
                        <div 
                            key={item.companyName}
                            className={selectedProject === item.companyName ? "-item active" : "-item"}
                            onClick={() => setSelectedProject(item.companyName)}
                        >
                            <img className='-logo' src={item.logo} alt={`${item.companyName} logo`} />
                            {item.companyName}
                        </div>
                    )
                ))}
            </div>
            <Input.TextArea
                rows={4}
                maxLength={250}
                className='-textarea xl offset'
                value={messageText}
                onChange={handleChangeMessage}
            />
        </Modal>
    );
}
