import {useState} from 'react';

export const useValidForm = (initialState, minLength) => {
    const defaultValue = initialState;
    const [value, setValue] = useState(initialState);

    const onChange = e => {
        setValue(e.target.value);
        validate(e.target.value);
    }
    const trigger = () => validate(value);

    const [isValid, setIsValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const validate = (val) => {
        if (val === "") {
            setIsValid(false);
            setErrorMessage('must not be empty')
        } else if (val.length <= minLength){
            setIsValid(false);
            setErrorMessage('must be more than ' + minLength.toString()  + ' symbols')
        } else {
            setIsValid(true);
            setErrorMessage('')
        }
    }

    return {value, defaultValue, setValue, isValid, errorMessage, onChange, trigger};
}
