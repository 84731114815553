export const networks = {
    arthera: {
        chainId: `0x${Number(10243).toString(16)}`,
        chainName: "Arthera Testnet",
        nativeCurrency: {
            name: "Arthera",
            symbol: "AA",
            decimals: 18
        },
        rpcUrls: ["https://rpc-test.arthera.net/"],
        blockExplorerUrls: ["https://explorer-test.arthera.net"]
    },
    zetachain: {
        chainId: `0x${Number(7001).toString(16)}`,
        chainName: "Zetachain Testnet",
        nativeCurrency: {
            name: "ZETA",
            symbol: "aZETA",
            decimals: 18
        },
        rpcUrls: ["https://rpc.ankr.com/zetachain_evm_athens_testnet"],
        blockExplorerUrls: ["https://explorer.zetachain.com/"]
    },
    scroll: {
        chainId: `0x${Number(534351).toString(16)}`,
        chainName: "Scroll Testnet",
        nativeCurrency: {
            name: "SCROLL",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://sepolia-rpc.scroll.io"],
        blockExplorerUrls: ["https://sepolia.scrollscan.com"]
    },
    linea: {
        chainId: `0x${Number(59140).toString(16)}`,
        chainName: "Linea Testnet",
        nativeCurrency: {
            name: "LINEA",
            symbol: "LineaETH",
            decimals: 18
        },
        rpcUrls: ["https://rpc.goerli.linea.build"],
        blockExplorerUrls: ["https://explorer.goerli.linea.build"]
    }
};

export const changeNetwork = async ({ networkName, setError }) => {
    try {
        if (!window.ethereum) throw new Error("No crypto wallet found");
        await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
                {
                    ...networks[networkName]
                }
            ]
        });
    } catch (err) {
        setError(err.message);
    }
};

export const factoryAddresses = {
    "10243": {
        isMainnet: false,
    },
    "10242": {
        isMainnet: true,
    },
    "7001": {
        isMainnet: false,
    },
    "534351": {
        isMainnet: false,
    },
    "59140": {
        isMainnet: false,
    },
    "arbitrum": {
        isMainnet: true,
    },
    "arbitrum-goerli": {
        isMainnet: false,
    },
    "bsc": {
        isMainnet: true,
    },
    "bsc-testnet": {
        isMainnet: true,
    },
    "homestead": {
        isMainnet: true,
    },
    "goerli": {
        isMainnet: false,
    },
    "maticmum": {
        isMainnet: true,
    },
    "matic": {
        isMainnet: true,
    },
    "optimism": {
        isMainnet: true,
    },
    "optimism-goerli": {
        isMainnet: true,
    },
    "scroll": {
        isMainnet: true,
    },
    "linea": {
        isMainnet: true,
    }
};
