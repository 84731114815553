import React, {useEffect, useState} from 'react';
import ForcToken from './ForcToken';

export default function MyTokens() {
    const columns1 = [
        {
            title: 'Token name',
            dataIndex: 'tokenName',
            key: 'tokenName',
        },
        {
            title: 'Claimable',
            dataIndex: 'claimable',
            key: 'claimable',
        },
        {
            title: 'Claimed',
            dataIndex: 'claimed',
            key: 'claimed',
        },
        {
            title: 'Remaining',
            dataIndex: 'remaining',
            key: 'remaining',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Next Release',
            dataIndex: 'nextRelease',
            key: 'nextRelease',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
        },
    ];
    const columns2 = [
        {
            title: 'Stake',
            dataIndex: 'stake',
            key: 'stake',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
        },
    ];
    const columns3 = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
        },
    ];
    const dataSource1 = [
        {
            key: '1',
            tokenName: 'Token 1',
            claimable: 10000,
            claimed: 3000,
            remaining: 140000,
            total: 143000,
            nextRelease: "12:00 08.07.2025"
        },
    ];
    const dataSource2 = [
        {
            key: '1',
            stake: 'Token2',
            amount:1000,
            date: "11:00 08.07.2025"
        },
    ];
    const dataSource3 = [
        {
            key: '1',
            name: 'Token 3',
            currency: "eth",
            amount:1000,
            date: "11:00 08.07.2025"
        },
    ];


    return (
        <div className="tokens-wrapper">
            <ForcToken />
            {/*<Card size="default" title="Bridge Assets">*/}
            {/*    <div className="tokens-wrapper">*/}
            {/*        <div>From</div>*/}
            {/*        <Select*/}
            {/*            defaultValue="lucy"*/}
            {/*            style={{ width: '100%' }}*/}
            {/*            options={[*/}
            {/*                { value: 'jack', label: 'Jack' },*/}
            {/*                { value: 'lucy', label: 'Lucy' },*/}
            {/*                { value: 'Yiminghe', label: 'yiminghe' },*/}
            {/*                { value: 'disabled', label: 'Disabled', disabled: true },*/}
            {/*            ]}>*/}

            {/*        </Select>*/}

            {/*        <div>To</div>*/}
            {/*        <Select*/}
            {/*            defaultValue="lucy"*/}
            {/*            style={{ width: '100%' }}*/}
            {/*            options={[*/}
            {/*                { value: 'jack', label: 'Jack' },*/}
            {/*                { value: 'lucy', label: 'Lucy' },*/}
            {/*                { value: 'Yiminghe', label: 'yiminghe' },*/}
            {/*                { value: 'disabled', label: 'Disabled', disabled: true },*/}
            {/*            ]}>*/}

            {/*        </Select>*/}
            {/*        <div>*/}
            {/*            <Button type="primary">Bridge</Button>*/}
            {/*        </div>*/}

            {/*    </div>*/}
            {/*</Card>*/}
            {/*<Card size="default" title="Claim Rewards">*/}
            {/*    <Table scroll={{ x: "max-content" }} dataSource={dataSource1} columns={columns1} />*/}
            {/*</Card>*/}
            {/*<div>*/}
            {/*    <Button type="primary">Unstake</Button>*/}
            {/*</div>*/}
            {/*<Card size="default" title="Active stake">*/}
            {/*    <Table scroll={{ x: "max-content" }} dataSource={dataSource2} columns={columns2} />*/}
            {/*</Card>*/}
            {/*<Card size="default" title="Investment stake">*/}
            {/*    <Table scroll={{ x: "max-content" }} dataSource={dataSource3} columns={columns3} />*/}
            {/*</Card>*/}
        </div>
    );
}
