import React, {useEffect, useState} from 'react';
import '../../assets/scss/_cabinet-settings.scss'
import Aim from "../../assets/icons/userSettings/aim.svg"
import Avatar from "../../assets/icons/userSettings/avatar.svg"
import Marker from "../../assets/icons/userSettings/marker.svg"
import axios from 'axios';
import { useAccount } from 'wagmi';

import {getUserProfile, saveCurator, saveUserProfile} from "../../controllers/UserProfileController";
import rolesLabels from "../../json/rolesLabels";
import {Button, Input, Radio, Tag, message} from 'antd';
import {useAuthenticate} from "../../hooks/useAuthenticate";
import FileUpload from '../elements/FileUpload';
import {shouldAuthenticate} from "../../utils/shouldAuthenticate";
import TelegramSubscribe from '../notifications/TelegramSubscribe';


function ProjectRemove({address}) {
    const [projectName, setProjectName] = useState("");

    const handleRemoveProject = (event) => {
        setProjectName(event.target.value);
    };

    const handleSubmit = async () => {
        await submitRemoveProject(projectName);
    };

    const submitRemoveProject = async (projectName) => {
        try {
            await axios.post(process.env.REACT_APP_API_ENDPOINT + '/secured/api/removeProject', {
                requestAddress: address,
                projectName: projectName,
            });
            message.success("Successfully removed project");
        } catch (error) {
            console.error(error);
            message.error("Failed to remove project");
        }
    };

    return (
        <div style={{display: "flex", gap: "10px"}}>
            <Input
                type="text"
                name="projectName"
                placeholder="Project name"
                onChange={handleRemoveProject}
                value={projectName}
            />
            <Button
                type="primary"
                onClick={handleSubmit}
            >
                Remove Project
            </Button>
        </div>
    );
}


function TestnetUser({address}) {
    const [whitelistAddress, setWhitelistAddress] = useState("");

    const handleChangeAddress = (event) => {
        setWhitelistAddress(event.target.value);
    };

    const handleSubmit = async () => {
        const whitelistAddresses = whitelistAddress.split(',');
        await submitTestnetUsers(whitelistAddresses);
    };

    const submitTestnetUsers = async (whitelistAddresses) => {
        try {
            await axios.post(process.env.REACT_APP_API_ENDPOINT + '/secured/api/setAsCurator', {
                requestAddress: address,
                walletAddress: whitelistAddresses,
            });
            message.success("Successfully added as curator");
        } catch (error) {
            console.error(error);
            message.error("Failed to add as curator");
        }
    };

    return (
        <div style={{display: "flex", gap: "10px"}}>
            <Input
                type="text"
                name="whitelistAddress"
                placeholder="Enter address"
                onChange={handleChangeAddress}
                value={whitelistAddress}
            />
            <Button
                type="primary"
                onClick={handleSubmit}
            >
                Add address as curator
            </Button>
        </div>
    );
}


export default function UserSettings(props) {
    const { TextArea } = Input;
    const { address } = useAccount();
    const { authenticate } = useAuthenticate();

    useEffect(() => {
        setUserData({
            nickName: props.userData.nickName,
            facebook: props.userData.facebook,
            discord: props.userData.discord,
            telegram: props.userData.telegram,
            twitter: props.userData.twitter,
            youtube: props.userData.youtube,
            linkedin: props.userData.linkedin,
            aboutme: props.userData.aboutme,
            email: props.userData.email
        });
        setRoles(props.userRoles);
        setReferralLink(props.userData.refLink);
        setUserRole(props.userData.userRole);
        setPreferredSocialMedia(props.userData.preferredSocialMedia);
    }, [props.userData, props.userRoles])

    const [userData, setUserData] = useState({
        walletAddress: props.userData.walletId,
        nickName: "",
        facebook: "",
        discord: "",
        telegram: props.userData.telegram,
        twitter: props.userData.twitter,
        youtube: props.userData.youtube,
        linkedin: props.userData.linkedin,
        aboutme: props.userData.aboutme,
    })

    const rolesKeys = Object.keys(rolesLabels);
    const [roles, setRoles] = useState(rolesKeys.reduce((json, key) => {
            json[key] = false;
            return json;
        }, {})
    )
    const [userRole, setUserRole] = useState("user");
    const [userAvatar, setUserAvatar] = useState(null);
    const [preferredSocialMedia, setPreferredSocialMedia] = useState(props.userData.preferredSocialMedia);
    const [referralLink, setReferralLink] = useState("");
    const handleChangeUserRole = (event) => setUserRole(event.target.value);
    const handleRolesChange = (role) => {
        const updatedRoles = { ...roles, [role]: !roles[role] };
        setRoles(updatedRoles);
    };


    const [walletAddress, setWalletAddress] = useState("")
    const [newWalletAddress, setNewWalletAddress] = useState("")

    const submit = async(e) => {
        e.preventDefault();

        if (shouldAuthenticate()){
            message.error("Unauthorized. Please sign in, and repeat the action.")
            authenticate(address);
        } else {
            const refLink = referralLink;
            let base64;
            if (userAvatar === null) {
                base64 = props.userData.avatarLogo
            } else {
                base64 = userAvatar;
            }

            if (props.isAdmin){
                await saveCurator(newWalletAddress, walletAddress, userData, roles, preferredSocialMedia, base64, userRole, refLink)
                    .then(() => {
                        message.success("Curator saved");
                        props.close();
                    });
            } else {
                await saveUserProfile(address, userData, roles, preferredSocialMedia, base64,
                    localStorage.getItem("referral") ? localStorage.getItem("referral").replace(/"/g, '') : "")
                    .then(() => {
                        message.success("Profile updated")
                        props.updateAvatar();
                        props.close();
                    })
                    .catch(() => {
                        message.error("Unauthorized")
                        props.close();
                    });
            }
        }
    }


    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        setUserData((prev) => {
            return {
                ...prev,   // Spread Operator
                [name]: value
            }
        })
    }



    const handleChangeNewWalletAddress = (event) => {
        setNewWalletAddress(event.target.value)
    }

    const handleChangeWalletAddress = async (event) => {
        setWalletAddress(event.target.value);
        await getUserProfile(event.target.value)
            .then(response => {
                if (response.status === 204){
                    const emptyProperties = ["nickName", "facebook", "discord", "telegram", "twitter", "youtube", "linkedin", "aboutme", "email"];
                    const emptyUserData = emptyProperties.reduce((acc, property) => {
                        acc[property] = "";
                        return acc;
                    }, {});
                    setUserData(emptyUserData);
                    const roleKeys = Object.keys(rolesLabels);

                    const emptyUserRoles = roleKeys.reduce((acc, property) => {
                        acc[property] = false;
                        return acc;
                    }, {});
                    setRoles(emptyUserRoles);
                } else {
                    setUserData({
                        nickName: response.data.nickName,
                        walletAddress: response.data.walletAddress,
                        facebook: response.data.facebook,
                        discord: response.data.discord,
                        telegram: response.data.telegram,
                        twitter: response.data.twitter,
                        youtube: response.data.youtube,
                        linkedin: response.data.linkedin,
                        aboutme: response.data.aboutme,
                        userRole: response.data.userRole,
                        email: response.data.email,
                        preferredSocialMedia: response.data.preferredSocialMedia,
                    });
                    const userRoles = Object.keys(rolesLabels).reduce((acc, key) => {
                        acc[key] = response.data[key] || false;
                        return acc;
                    }, {});
                    setRoles(userRoles);
                }
            })
    }

    const socialMediaPlatforms = [
        { name: 'telegram', label: 'Telegram' },
        { name: 'twitter', label: 'Twitter' },
        { name: 'discord', label: 'Discord' },
        { name: 'youtube', label: 'Youtube' },
        { name: 'facebook', label: 'Facebook' },
        { name: 'linkedin', label: 'Linkedin' },
    ];
    return (
        <form onSubmit={submit}>
            <div className='settings-page'>
                {props.isAdmin &&
                    <>

                        <div className='-grid admin-section'>
                            <div>
                                <div className='-section'>
                                    {/* <img src={Avatar} className='-img' alt=""/> */}
                                    <div className='-text'>Curators and projects management</div>
                                </div>
                            </div>
                            <div className='-input-wrapper'>
                                <TestnetUser address={address}/>

                                <ProjectRemove address={address}/>
                            </div>
                        </div>
                        <div className='-text xl'>Update user information</div>

                        <div className='-grid'>
                            <div>
                                <div className='-section'>
                                    <img src={Avatar} className='-img' alt=""/>
                                    <div className='-text'>Wallet addres</div>
                                </div>
                            </div>
                            <div className='-input-grid'>
                                <div className='-text'>Address</div>
                                <Input
                                    type="text"
                                    name='nickName'
                                    value={userData.walletAddress}
                                    onChange={handleChangeWalletAddress}
                                />
                            </div>
                        </div>
                        <div className='-grid'>
                            <div>
                                <div className='-section'>
                                    <img src={Avatar} className='-img' alt=""/>
                                    <div className='-text'>New Wallet address</div>
                                </div>
                            </div>
                            <div className='-input-grid'>
                                <div className='-text'>Address</div>
                                <Input
                                    type="text"
                                    name='nickName'
                                    value={newWalletAddress}
                                    onChange={handleChangeNewWalletAddress}
                                />
                            </div>
                        </div>
                    </>
                }
                <div className='-grid'>
                    <div>
                        <div className='-section'>
                            <img src={Marker} className='-img' alt=""/>
                            <div className='-text'>Avatar</div>
                        </div>
                    </div>
                    <div className='-input-grid'>
                        <div className='-text'></div>
                        <FileUpload onFileChange={setUserAvatar}/>
                    </div>
                </div>
                <div className='-grid'>
                    <div>
                        <div className='-section'>
                            <img src={Avatar} className='-img' alt=""/>
                            <div className='-text'>Personal Information</div>
                        </div>
                    </div>
                    <div className='-input-grid'>
                        <div className='-text'>Username</div>
                        <Input
                            type="text"
                            name='nickName'
                            value={userData.nickName}
                            onChange={handleChange}
                        />
                    </div>

                </div>
                <div className='-grid'>
                    <div>
                        <div className='-section'>
                            <img src={Marker} className='-img' alt=""/>
                            <div className='-text'>Nickname</div>
                        </div>
                    </div>
                    <div className="-input-wrapper">
                        <div className='-input-grid'>
                            <div className='-text'>Email</div>
                            <Input
                                type="text"
                                name='email'
                                value={userData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="-input-grid">
                            <div className='-text xs'>About me</div>
                            <TextArea
                                type="text"
                                name='aboutme'
                                maxLength="250"
                                value={userData.aboutme}
                                onChange={handleChange}
                            />
                        </div>

                    </div>
                </div>
                    <div className="-grid">
                        <div>
                            <div className='-section'>
                                <img src={Aim} className='-img' alt=""/>
                                <div className='-text'>Social Media Accounts</div>
                            </div>
                        </div>
                        <div className="-input-wrapper">
                            {socialMediaPlatforms.map(({ name, label }) => (
                                <div className='-input-grid' key={name}>
                                    <div className='-text'>{label}</div>
                                    <Input
                                        type="text"
                                        name={name}
                                        value={userData[name]}
                                        onChange={handleChange}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="-grid disable-border">
                        <div></div>
                        <div className="-left">
                            {props.isAdmin &&
                                <Radio.Group
                                    name="controlled-radio-buttons-group"
                                    onChange={handleChangeUserRole}
                                    value={userRole}c
                                    optionType="button"
                                    buttonStyle="solid"
                                >
                                    <Radio value="user" checked={userRole === "user"}>User</Radio>
                                    <Radio  value="Curator" checked={userRole === "Curator"}>Curator</Radio>
                                    {props.isAdmin &&
                                        <Radio value="Viewer" checked={userRole === "Viewer"}>Viewer</Radio>
                                    }
                                    <Radio  value="angelInvestor" disabled>Angel Investor</Radio>
                                </Radio.Group>
                            }
                        </div>
                    </div>
                    <div>
                <div>
                    {userRole === 'Curator' &&
                        <div className='-tags'>
                            {rolesKeys.map((role, key) =>
                                <Tag
                                    key={key}
                                    color={roles[role] ? 'geekblue' : 'default'}
                                    onClick={() => handleRolesChange(role)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {rolesLabels[role]}
                                </Tag>
                            )}
                        </div>
                    }
                </div>
                <div className="-left">
                    <Button style={{marginTop: '8px'}} type="primary" htmlType="submit">Update my profile</Button>
                </div>
                </div>

            </div>
        </form>
    );
}
