import { useState, useEffect } from "react";
export const useTimer = (date, isWorking=true) => {
    const[deadline, setDeadline] = useState(new Date(date));

    const time = Math.abs(new Date().valueOf() - deadline.valueOf());

    const [days, setDays] = useState(Math.floor(time / (1000 * 60 * 60 * 24)));
    const [hours, setHours] = useState(Math.floor((time / (1000 * 60 * 60)) % 24));
    const [minutes, setMinutes] = useState(Math.floor((time / 1000 / 60) % 60));
    const [seconds, setSeconds] = useState(Math.floor((time / 1000) % 60));

    const getTime = () => {
        if( (new Date().valueOf() - deadline.valueOf()) < 0) {
            const time = Math.abs(new Date().valueOf() - deadline.valueOf());

            setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
            setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
            setMinutes(Math.floor((time / 1000 / 60) % 60));
            setSeconds(Math.floor((time / 1000) % 60));
        }
    };

    useEffect(() => {
        if (isWorking) {
            const interval = setInterval(() => getTime(deadline), 1000);
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line
    }, [date]);

    return { days, hours, minutes, seconds, setDeadline};

}
