import convertToBase64 from "../utils/FileConverter";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export async function initializeDfo(_companyName, owner, dfoTicker, dfoType, file, network, referralAddress) {
    try {
        const base64 = await convertToBase64(file);

        return await axios.post(apiUrl + '/api/initializeDFO', {
            companyName: _companyName,
            owner: owner,
            logo: base64,
            ticker: dfoTicker,
            multisigAddresses: [],
            numOfMultisigConfirmations: 1,
            isPrivate: dfoType !== "public",
            network: network,
            referralAddress: referralAddress
        });
    } catch (error) {
        // Handle errors
        console.error('Error during API request:', error);
        throw error; // Re-throw the error for the caller to handle
    }
}
